<footer class="site-footer">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <p>
                    &copy; Copyright {{ yearNow }} - <a target="_blank" class="color-text" href="http://breinergonza.net/">Breitner Gonzalez</a>
                    <br> All rights reserved
                </p>
            </div>
        </div>
    </div>
</footer>

<div class="scroll-top">
    <span class="lnr lnr-chevron-up"></span>
</div>