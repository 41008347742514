<app-navbar></app-navbar>

<app-encabezado></app-encabezado>

<main class="content-wrap">

    <section>
      <div class="container">

        <div class="row">
         <div class="col-sm-12 text-center mb-5">
           <h2 class="section-title">
             ULTIMAS NOTICIAS
           </h2>
           <span class="background-text">blog</span>
         </div>
        </div>

        <div class="row">

          <div class="col-md-4 wow fadeIn" data-wow-duration="1s" data-wow-delay=".1s">
            <article class="blog-post">
              <div class="post-img">
                <img src="assets/img/blog/1.jpg" alt="">
              </div>
             <div class="post-content">
                <h2 class="post-title">
                  How to Improve Your Design!
                </h2>
              <div class="post-meta">
                Saifullah  |  9 Dec 2016
              </div>
                <p>
                  Nam liber tempor cum soluta nobis is eleifend option congue nihil imper per tem por legere me doming vulputate.
                </p>
                <a [routerLink]="['/detalle']" class="btn btn-theme mt-2">Read more</a>
              </div>
            </article>
          </div>

          <div class="col-md-4 wow fadeIn" data-wow-duration="1s" data-wow-delay=".2s">
            <article class="blog-post">
              <div class="post-img">
                <img src="assets/img/blog/2.jpg" alt="">
              </div>
             <div class="post-content">
                <h2 class="post-title">
                  16 Free Resources for Web Designers for May 2017
                </h2>
              <div class="post-meta">
                Saifullah  |  9 Dec 2016
              </div>
                <p>
                  Nam liber tempor cum soluta nobis is eleifend option congue nihil imper per
                </p>
                <a [routerLink]="['/detalle']" class="btn btn-theme mt-2">Read more</a>
              </div>
            </article>
          </div>

          <div class="col-md-4 wow fadeIn" data-wow-duration="1s" data-wow-delay=".3s">
            <article class="blog-post">
              <div class="post-img">
                <img src="assets/img/blog/3.jpg" alt="">
              </div>
             <div class="post-content">
                <h2 class="post-title">
                  How to Improve Your Design!
                </h2>
              <div class="post-meta">
                Saifullah  |  9 Dec 2016
              </div>
                <p>
                  Nam liber tempor cum soluta nobis is eleifend option congue nihil imper per tem por legere me doming vulputate.
                </p>
                <a [routerLink]="['/detalle']" class="btn btn-theme mt-2">Read more</a>
              </div>
            </article>
          </div>

        </div>

        <div class="row">

          <div class="col-md-4 wow fadeIn" data-wow-duration="1s" data-wow-delay=".1s">
            <article class="blog-post">
              <div class="post-img">
                <img src="assets/img/blog/4.jpg" alt="">
              </div>
             <div class="post-content">
                <h2 class="post-title">
                  Design Inspiration #1
                </h2>
              <div class="post-meta">
                Saifullah  |  9 Dec 2016
              </div>
                <p>
                  Nam liber tempor cum soluta nobis is eleifend option congue nihil imper per tem por legere me doming vulputate.
                </p>
                <a [routerLink]="['/detalle']" class="btn btn-theme mt-2">Read more</a>
              </div>
            </article>
          </div>

          <div class="col-md-4 wow fadeIn" data-wow-duration="1s" data-wow-delay=".2s">
            <article class="blog-post">
              <div class="post-img">
                <img src="assets/img/blog/5.jpg" alt="">
              </div>
             <div class="post-content">
                <h2 class="post-title">
                  Badass Premium Templates
                </h2>
              <div class="post-meta">
                Saifullah  |  9 Dec 2016
              </div>
                <p>
                  Nam liber tempor cum soluta nobis is eleifend option congue nihil imper per tem por legere me doming vulputate.
                </p>
                <a [routerLink]="['/detalle']" class="btn btn-theme mt-2">Read more</a>
              </div>
            </article>
          </div>

          <div class="col-md-4 wow fadeIn" data-wow-duration="1s" data-wow-delay=".3s">
            <article class="blog-post">
              <div class="post-img">
                <img src="assets/img/blog/6.jpg" alt="">
              </div>
             <div class="post-content">
                <h2 class="post-title">
                  Best Hosts Bootstrap Projects
                </h2>
              <div class="post-meta">
                Saifullah  |  9 Dec 2016
              </div>
                <p>
                  Nam liber tempor cum soluta nobis is eleifend option congue nihil imper per tem por legere me doming vulputate.
                </p>
                <a [routerLink]="['/detalle']" class="btn btn-theme mt-2">Read more</a>
              </div>
            </article>
          </div>

        </div>

        <div class="row">

          <div class="col-md-4 wow fadeIn" data-wow-duration="1s" data-wow-delay=".1s">
            <article class="blog-post">
              <div class="post-img">
                <img src="assets/img/blog/1.jpg" alt="">
              </div>
             <div class="post-content">
                <h2 class="post-title">
                  How to Improve Your Design!
                </h2>
              <div class="post-meta">
                Saifullah  |  9 Dec 2016
              </div>
                <p>
                  Nam liber tempor cum soluta nobis is eleifend option congue nihil imper per tem por legere me doming vulputate.
                </p>
                <a [routerLink]="['/detalle']" class="btn btn-theme mt-2">Read more</a>
              </div>
            </article>
          </div>

          <div class="col-md-4 wow fadeIn" data-wow-duration="1s" data-wow-delay=".2s">
            <article class="blog-post">
              <div class="post-img">
                <img src="assets/img/blog/2.jpg" alt="">
              </div>
             <div class="post-content">
                <h2 class="post-title">
                  16 Free Resources for Web Designers for May 2017
                </h2>
              <div class="post-meta">
                Saifullah  |  9 Dec 2016
              </div>
                <p>
                  Nam liber tempor cum soluta nobis is eleifend option congue nihil imper per
                </p>
                <a [routerLink]="['/detalle']" class="btn btn-theme mt-2">Read more</a>
              </div>
            </article>
          </div>

          <div class="col-md-4 wow fadeIn" data-wow-duration="1s" data-wow-delay=".3s">
            <article class="blog-post">
              <div class="post-img">
                <img src="assets/img/blog/3.jpg" alt="">
              </div>
             <div class="post-content">
                <h2 class="post-title">
                  How to Improve Your Design!
                </h2>
              <div class="post-meta">
                Saifullah  |  9 Dec 2016
              </div>
                <p>
                  Nam liber tempor cum soluta nobis is eleifend option congue nihil imper per tem por legere me doming vulputate.
                </p>
                <a [routerLink]="['/detalle']" class="btn btn-theme mt-2">Read more</a>
              </div>
            </article>
          </div>

        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="theme-pagination">
              <ul class="list-inline text-center">
                <li class="list-inline-item">
                  <a href="#"><span class="lnr lnr-arrow-left"></span> Previous</a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <strong> 01 </strong>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <strong> 02 </strong>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <strong> 03 </strong>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <strong> 04 </strong>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <strong> 05 </strong>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">Next <span class="lnr lnr-arrow-right"></span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </section>

    </main>

    <app-footer></app-footer>
