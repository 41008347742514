<app-navbar></app-navbar>

<app-encabezado></app-encabezado>

<!--
<app-encabezado>
</app-encabezado> -->

<main class="content-wrap">
  <section>
    <div class="container">

      <div class="row">
        <div class="col-sm-12 text-center mb-5 wow zoomIn">
          <h2 class="section-title">
            Mi trabajo
          </h2>
          <span class="background-text">portafolio</span>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 text-center my-4">
          <ul class="portfolio-filter list-inline">
            <li class="active" data-filter="*">All</li>
            <li data-filter=".cat1">Creativo</li>
            <li data-filter=".cat2">Corporativo</li>
            <li data-filter=".cat3">Onepage</li>
            <li data-filter=".cat4">Ecommerce</li>
            <li data-filter=".cat5">.Net</li>
          </ul>
        </div>
      </div>

      <div class="row portfolio">

        <div class="col-md-6 col-lg-4 portfolio-item cat1 cat5">
          <div class="portfolio-block">
            <img src="assets/img/portfolio/1.jpg" alt="">
            <div class="portfolio-overlay">
              <div class="portfolio-btn-action">
                <a href="#">
                  <span class="lnr lnr-link"></span>
                </a>
                <a href="assets/img/portfolio/1.jpg" class="popup-img">
                  <span class="lnr lnr-frame-expand"></span>
                </a>
              </div>
              <div class="portfolio-desc">
                <h4>Creative Landing Page</h4>
                <p>Design & Branding</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 portfolio-item cat2 cat3">
          <div class="portfolio-block">
            <img src="assets/img/portfolio/2.jpg" alt="">
            <div class="portfolio-overlay">
              <div class="portfolio-btn-action">
                <a href="">
                  <span class="lnr lnr-link"></span>
                </a>
                <a href="assets/img/portfolio/2.jpg" class="popup-img">
                  <span class="lnr lnr-frame-expand"></span>
                </a>
              </div>
              <div class="portfolio-desc">
                <h4>Creative Landing Page</h4>
                <p>Design & Branding</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 portfolio-item cat1 cat2 cat5">
          <div class="portfolio-block">
            <img src="assets/img/portfolio/3.jpg" alt="">
            <div class="portfolio-overlay">
              <div class="portfolio-btn-action">
                <a href="#">
                  <span class="lnr lnr-link"></span>
                </a>
                <a href="assets/img/portfolio/3.jpg" class="popup-img">
                  <span class="lnr lnr-frame-expand"></span>
                </a>
              </div>
              <div class="portfolio-desc">
                <h4>Creative Landing Page</h4>
                <p>Design & Branding</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 portfolio-item cat3 cat4 cat1">
          <div class="portfolio-block">
            <img src="assets/img/portfolio/4.jpg" alt="">
            <div class="portfolio-overlay">
              <div class="portfolio-btn-action">
                <a href="#">
                  <span class="lnr lnr-link"></span>
                </a>
                <a href="assets/img/portfolio/4.jpg" class="popup-img">
                  <span class="lnr lnr-frame-expand"></span>
                </a>
              </div>
              <div class="portfolio-desc">
                <h4>Creative Landing Page</h4>
                <p>Design & Branding</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 portfolio-item cat3 cat1 cat5">
          <div class="portfolio-block">
            <img src="assets/img/portfolio/5.jpg" alt="">
            <div class="portfolio-overlay">
              <div class="portfolio-btn-action">
                <a href="#">
                  <span class="lnr lnr-link"></span>
                </a>
                <a href="assets/img/portfolio/5.jpg" class="popup-img">
                  <span class="lnr lnr-frame-expand"></span>
                </a>
              </div>
              <div class="portfolio-desc">
                <h4>Creative Landing Page</h4>
                <p>Design & Branding</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 portfolio-item cat5 cat4">
          <div class="portfolio-block">
            <img src="assets/img/portfolio/6.jpg" alt="">
            <div class="portfolio-overlay">
              <div class="portfolio-btn-action">
                <a href="#">
                  <span class="lnr lnr-link"></span>
                </a>
                <a href="assets/img/portfolio/6.jpg" class="popup-img">
                  <span class="lnr lnr-frame-expand"></span>
                </a>
              </div>
              <div class="portfolio-desc">
                <h4>Creative Landing Page</h4>
                <p>Design & Branding</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 portfolio-item cat3 cat1">
          <div class="portfolio-block">
            <img src="assets/img/portfolio/7.jpg" alt="">
            <div class="portfolio-overlay">
              <div class="portfolio-btn-action">
                <a href="#">
                  <span class="lnr lnr-link"></span>
                </a>
                <a href="assets/img/portfolio/7.jpg" class="popup-img">
                  <span class="lnr lnr-frame-expand"></span>
                </a>
              </div>
              <div class="portfolio-desc">
                <h4>Creative Landing Page</h4>
                <p>Design & Branding</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 portfolio-item cat2 cat3">
          <div class="portfolio-block">
            <img src="assets/img/portfolio/2.jpg" alt="">
            <div class="portfolio-overlay">
              <div class="portfolio-btn-action">
                <a href="#">
                  <span class="lnr lnr-link"></span>
                </a>
                <a href="assets/img/portfolio/2.jpg" class="popup-img">
                  <span class="lnr lnr-frame-expand"></span>
                </a>
              </div>
              <div class="portfolio-desc">
                <h4>Creative Landing Page</h4>
                <p>Design & Branding</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 portfolio-item cat1 cat2 cat5">
          <div class="portfolio-block">
            <img src="assets/img/portfolio/3.jpg" alt="">
            <div class="portfolio-overlay">
              <div class="portfolio-btn-action">
                <a href="#">
                  <span class="lnr lnr-link"></span>
                </a>
                <a href="assets/img/portfolio/3.jpg" class="popup-img">
                  <span class="lnr lnr-frame-expand"></span>
                </a>
              </div>
              <div class="portfolio-desc">
                <h4>Creative Landing Page</h4>
                <p>Design & Branding</p>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>




  <section class="call-to-action bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h2 class="section-title">Inicia tu poyecto conmigo!</h2>
          <p></p>
          <a [routerLink]="['/contacto']" class="btn btn-theme btn-active mt-2">contactame</a>
        </div>
      </div>
    </div>
  </section>


</main>

<app-footer></app-footer>
