<app-navbar></app-navbar>

<app-encabezado></app-encabezado>

<main class="content-wrap">
    <section class="pb-0">

        <div class="container">

            <div class="row">
                <div class="col-sm-12 text-center mb-5 wow zoomIn">
                    <h2 class="section-title">
                        Ponte en contacto
                    </h2>
                    <span class="background-text">contacto</span>
                </div>
            </div>

            <div class="row">

                <div class="col-lg-8 pt-md-5 wow fadeIn">
                    <h4 class="block-title">Siente libre de escribirme</h4>
                    <p>
                        Tratare de responderte lo antes posible...
                    </p>

                    <form class="form-contact" action="#" method="POST">

                        <div class="row">

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <input class="form-control" type="text" name="name" placeholder="Nombre" required>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <input class="form-control" type="email" name="email" placeholder="Correo" required>
                                </div>
                            </div>

                        </div>
                        <div class="form-group">
                            <textarea class="form-control" name="message" placeholder="Tu mensaje..." rows="5" required></textarea>
                        </div>

                        <button class="btn btn-theme btn-active" type="submit">Enviar mensaje</button>
                        <div class="form-send-callback"></div>
                    </form>

                </div>

                <div class="col-lg-4 mt-5">

                    <div class="contact-block wow zoomIn">
                        <h4>Información de contacto</h4>

                        <div class="contact-info">
                            <div class="con-icon">
                                <span class="lnr lnr-phone-handset"></span>
                            </div>
                            <div class="con-text">
                                Celular: +57 311-652-0574
                            </div>
                        </div>

                        <div class="contact-info">
                            <div class="con-icon">
                                <span class="lnr lnr-envelope"></span>
                            </div>
                            <div class="con-text">
                                <a href="mailto:breinergonza@hotmail.com">breinergonza@hotmail.com</a>
                            </div>
                        </div>

                        <div class="contact-info">
                            <div class="con-icon">
                                <span class="lnr lnr-map-marker"></span>
                            </div>
                            <div class="con-text">
                                Bogotá - Colombia
                            </div>
                        </div>

                        <div class="social mt-4">
                            <a href="https://www.facebook.com/breinergonza" target="_blank" class="color-social"><i class="fa fa-facebook"></i></a>
                            <a href="https://twitter.com/breinergonza" target="_blank" class="color-social"><i class="fa fa-twitter"></i></a>
                            <a href="https://plus.google.com/u/0/+breinergonzalez" target="_blank" class="color-social"><i class="fa fa-google-plus"></i></a>
                            <a href="https://www.linkedin.com/in/breitner-enrique-gonzález-angarita-847a2840" target="_blank" class="color-social"><i class="fa fa-linkedin"></i></a>
                        </div>
                    </div>

                </div>

            </div>

            <!-- <div class="row">
         <div class="col-sm-12">
           <div id="map" style="width:100%; height:450px;"></div>
         </div>
       </div> -->

        </div>
    </section>





    <section class="contact-bottom-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 text-center">
                    <h2 class="contact-hero-text">
                        En los últimos años he participado en más de <span class="color-text">30 proyectos</span> para clientes tanto en el sector <span class="text-blue">privado</span> como en el sector <span class="text-blue">público</span>. Me encantaria
                        sumarme al tuyo!
                    </h2>
                </div>
            </div>
        </div>
    </section>


</main>


<app-footer></app-footer>