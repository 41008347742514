<app-navbar></app-navbar>

<app-encabezado></app-encabezado>

<main class="content-wrap">
    <section class="education">
        <div class="container">

            <div class="row">
                <div class="col-sm-12 text-center mb-5 wow zoomIn">
                    <h2 class="section-title">
                        educación
                    </h2>
                    <span class="background-text">educación</span>
                </div>
            </div>

            <div class="row">

                <div class="col-md-6 mt-5">
                    <div class="edu-block wow fadeIn" data-wow-duration="1s" data-wow-delay=".1s">
                        <p class="edu-session">En curso</p>
                        <div class="edu-info-text">
                            <h4 class="block-title">Maestría en Ciencias de la Información y las Comunicaciones</h4>
                            <h6 class="institute-name mb-4 mt-3">Universidad Distrital Francisco José de Caldas</h6>
                            <p>
                                Profundización en Ingeniería de Software
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 mt-5">
                    <div class="edu-block wow fadeIn" data-wow-duration="1s" data-wow-delay=".2s">
                        <p class="edu-session">2020</p>
                        <div class="edu-info-text">
                            <h4 class="block-title">Especialista en Ingeniería de Software</h4>
                            <h6 class="institute-name mb-4 mt-3">Universidad Distrital Francisco José de Caldas</h6>
                            <p>
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col-md-6 mt-5">
                    <div class="edu-block wow fadeIn" data-wow-duration="1s" data-wow-delay=".3s">
                        <p class="edu-session">2017</p>
                        <div class="edu-info-text">
                            <h4 class="block-title">Ingeniero de Sistemas</h4>
                            <h6 class="institute-name mb-4 mt-3">Universidad Popular del Cesar</h6>
                            <p></p>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 mt-5">
                    <div class="edu-block wow fadeIn" data-wow-duration="1s" data-wow-delay=".4s">
                        <p class="edu-session">2007</p>
                        <div class="edu-info-text">
                            <h4 class="block-title">Tec. Ingeniero de Sistemas </h4>
                            <h6 class="institute-name mb-4 mt-3">UPARSISTEM</h6>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>





    <section class="work-experience bg-gray">
        <div class="container">

            <div class="row">
                <div class="col-sm-12 text-center mb-5 wow zoomIn">
                    <h2 class="section-title">
                        Experiencia laboral
                    </h2>
                    <span class="background-text">Experiencia</span>
                </div>
            </div>

            <div class="row">

                <div class="col-md-6 mt-5">
                    <div class="work-exp-block wow fadeIn" data-wow-duration="1s" data-wow-delay=".1s">
                        <h4 class="block-title">Líder de Desarrollo / DevOps Team Leader</h4>
                        <h6 class="company-name color-text">IFX Networks</h6>
                        <div class="working-duration">
                            may 2019 - act
                        </div>
                        <p>
                            Líder del equipo de Desarrollo Cloud para el desarrollo de soluciones con plataformas bajo tecnologías como Angular, ASP Net Core, JavaServer faces, Docker, Kubernetes, Xamarin, Java EE, ASP.Net MVC, Azure DevOps con pipelines de integración continua y despliegue continuo, Jenkins con pruebas automatizadas para Angular, SQL Server, Hyper-V, VMware, OracleVm, PowerShell, infraestructura como código para el aprovisionamiento automático en ambientes Multi-Cloud, brokers de servicios como Mulesoft, Apigee – Google Cloud, Azure API Management, y brokers de mensajería como RabbitMQ y MQTT.
                        </p>
                    </div>
                </div>

                <div class="col-md-6 mt-5">
                    <div class="work-exp-block wow fadeIn" data-wow-duration="1s" data-wow-delay=".2s">
                        <h4 class="block-title">Desarrollador full stack .Net</h4>
                        <h6 class="company-name color-text">Squad Digital - Stefanini</h6>
                        <div class="working-duration">
                            ene 2019 - may 2019
                        </div>
                        <p>
                            Desarrollador de microservicios bajo metodología DevOps en proyecto de transformación digital soportado en tecnologías y plataformas como Asp Core, Service Fabric, Azure API Management, Docker, Kubernetes, Azure Cosmo DB, SQL Server, MongoDB. 
                        </p>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col-md-6 mt-5">
                    <div class="work-exp-block wow fadeIn" data-wow-duration="1s" data-wow-delay=".3s">
                        <h4 class="block-title">Líder Técnico</h4>
                        <h6 class="company-name color-text">DB-SYSTEM</h6>
                        <div class="working-duration">
                            2016 - 2019
                        </div>
                        <p>
                            Líder técnico de diferentes equipos y proyectos de desarrollo en la fabrica de software de DB System.
                        </p>
                    </div>
                </div>

                <div class="col-md-6 mt-5">
                    <div class="work-exp-block wow fadeIn" data-wow-duration="1s" data-wow-delay=".4s">
                        <h4 class="block-title">Fénix Móviles</h4>
                        <h6 class="company-name color-text">Coordinador de Desarrollo</h6>
                        <div class="working-duration">
                            2014 - 2015
                        </div>
                        <p>
                            Web Developer y coordinador del equipo de desarrollo de la compañía, usando tecnologías ASP.NET en el desarrollo de aplicaciones con .NET y Android.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </section>




    <section class="skills">
        <div class="container">

            <div class="row">
                <div class="col-sm-12 text-center mb-5 wow zoomIn">
                    <h2 class="section-title">
                        Mis Conocimientos
                    </h2>
                    <span class="background-text">Conocimientos</span>
                </div>
            </div>

            <div class="row">

                <div class="col-md-6 pt-4">
                    <h4 class="block-title"></h4>

                    <div class="skill wow fadeIn">
                        <p class="skill-name">WordPress</p>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-valuenow="85"></div>
                        </div>
                    </div>

                    <div class="skill">
                        <p class="skill-name">JavaScript</p>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-valuenow="95"></div>
                        </div>
                    </div>

                    <div class="skill">
                        <p class="skill-name">PHP</p>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-valuenow="80"></div>
                        </div>
                    </div>

                    <div class="skill">
                        <p class="skill-name">JavaScript & jQuery</p>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-valuenow="90"></div>
                        </div>
                    </div>

                </div>

                <div class="col-md-6 pt-4">

                    <h4 class="block-title"></h4>

                    <div class="skill">
                        <p class="skill-name">Photoshop</p>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-valuenow="95"></div>
                        </div>
                    </div>

                    <div class="skill">
                        <p class="skill-name">UI/UX</p>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-valuenow="85"></div>
                        </div>
                    </div>
                    <div class="skill">
                        <p class="skill-name">Info Graphic</p>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-valuenow="95"></div>
                        </div>
                    </div>

                    <div class="skill">
                        <p class="skill-name">Animation</p>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-valuenow="75"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>


</main>

<app-footer></app-footer>