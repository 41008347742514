import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// Rutas
import { APP_ROUTING } from './app.routes';

// Servicios


// Componentes
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { AboutComponent } from './components/about/about.component';
import { CurriculumComponent } from './components/curriculum/curriculum.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { PortafolioComponent } from './components/portafolio/portafolio.component';
import { BlogComponent } from './components/blog/blog.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { EncabezadoComponent } from './components/shared/encabezado/encabezado.component';
import { DetalleComponent } from './components/blog/detalle/detalle.component';



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    AboutComponent,
    CurriculumComponent,
    ServiciosComponent,
    PortafolioComponent,
    BlogComponent,
    ContactoComponent,
    PageNotFoundComponent,
    EncabezadoComponent,
    DetalleComponent
  ],
  imports: [
    BrowserModule,
    APP_ROUTING,
    RouterModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
