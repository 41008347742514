import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './components/home/home.component';
import { CurriculumComponent } from './components/curriculum/curriculum.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { PortafolioComponent } from './components/portafolio/portafolio.component';
import { BlogComponent } from './components/blog/blog.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { DetalleComponent } from './components/blog/detalle/detalle.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { config } from 'rxjs';

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled'
};

const APP_ROUTES: Routes = [
  {path: 'inicio', component: HomeComponent},
  {path: 'curriculum', component: CurriculumComponent},
  {path: 'servicios', component: ServiciosComponent},
  {path: 'portafolio', component: PortafolioComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'contacto', component: ContactoComponent},
  {path: 'detalle', component: DetalleComponent},
  { path: '',
    redirectTo: '/inicio',
    pathMatch: 'full'
  },
  { path: '**', component: PageNotFoundComponent }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, routerOptions);
