<app-navbar></app-navbar>

<app-encabezado></app-encabezado>

<main class="content-wrap">
  <section class="services">

    <div class="container">

      <div class="row">
       <div class="col-sm-12 text-center mb-5 wow zoomIn">
         <h2 class="section-title">
           Que puedo hacer?
         </h2>
         <span class="background-text">servicios</span>
       </div>
      </div>

      <div class="row">

        <div class="col-md-4 text-center wow fadeIn" data-wow-duration="1s" data-wow-delay=".1s">
          <div class="service-block equal-height">
            <div class="sr-icon">
              <img src="assets/img/icons/sr1.png" alt="">
            </div>
            <h4 class="block-title">
              Software Design
            </h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming
            </p>
          </div>
        </div>

        <div class="col-md-4 text-center wow fadeIn" data-wow-duration="1s" data-wow-delay=".2s">
          <div class="service-block equal-height">
            <div class="sr-icon">
              <img src="assets/img/icons/sr2.png" alt="">
            </div>
            <h4 class="block-title">
              Web Design
            </h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming
            </p>
          </div>
        </div>

        <div class="col-md-4 text-center wow fadeIn" data-wow-duration="1s" data-wow-delay=".3s">
          <div class="service-block equal-height">
            <div class="sr-icon">
              <img src="assets/img/icons/sr3.png" alt="">
            </div>
            <h4 class="block-title">
              Web Development
            </h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming
            </p>
          </div>
        </div>

      </div>

      <div class="row">

        <div class="col-md-4 text-center wow fadeIn" data-wow-duration="1s" data-wow-delay=".1s">
          <div class="service-block equal-height">
            <div class="sr-icon">
              <img src="assets/img/icons/sr3.png" alt="">
            </div>
            <h4 class="block-title">
              Graphic Design
            </h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming
            </p>
          </div>
        </div>

        <div class="col-md-4 text-center wow fadeIn" data-wow-duration="1s" data-wow-delay=".2s">
          <div class="service-block equal-height">
            <div class="sr-icon">
              <img src="assets/img/icons/sr5.png" alt="">
            </div>
            <h4 class="block-title">
              Plugin Development
            </h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming
            </p>
          </div>
        </div>

        <div class="col-md-4 text-center wow fadeIn" data-wow-duration="1s" data-wow-delay=".3s">
          <div class="service-block equal-height">
            <div class="sr-icon">
              <img src="assets/img/icons/sr6.png" alt="">
            </div>
            <h4 class="block-title">
              App Development
            </h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming
            </p>
          </div>
        </div>

      </div>
    </div>
  </section>




  <section class="work-process pb-0">
    <div class="container">

      <div class="row">
       <div class="col-sm-12 text-center">
         <h2 class="section-title">
           How i work?
         </h2>
         <span class="background-text">process</span>
       </div>
      </div>

      <div class="row mt-100 d-md-flex align-items-center">

        <div class="col-md-6">
          <div class="row">
            <div class="col-md-8">
             <div class="work-proc-icon">
              <img src="assets/img/icons/Work-Process_1.png" alt="">
             </div>
            </div>

            <div class="col-md-4 text-center">
              <div class="work-process-count wow slideInDown">
                <h4>01</h4>
                <img src="assets/img/icons/arrow-right.png" alt="">
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="work-process-text wow zoomIn">
            <h4 class="block-title color-text mb-4">Planning & UX Research</h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming vulputate. Nam liber tem por cum soluta nobis eleifend option congue nihil imper.
            </p>
          </div>
        </div>

      </div>

      <div class="row mt-100 d-md-flex align-items-center">

        <div class="col-md-6 flex-md-last">
          <div class="row d-md-flex">

            <div class="col-md-8 flex-md-last">
             <div class="work-proc-icon">
              <img src="assets/img/icons/Work-Process_2.png" alt="">
             </div>
            </div>

            <div class="col-md-4 text-center">
              <div class="work-process-count wow slideInDown">
                <h4>02</h4>
                <img src="assets/img/icons/arrow-left.png" alt="">
              </div>
            </div>
          </div>
        </div>

         <div class="col-md-6">
          <div class="work-process-text wow zoomIn">
            <h4 class="block-title color-text mb-4">Design & Development</h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming vulputate. Nam liber tem por cum soluta nobis eleifend option congue nihil imper.
            </p>
          </div>
        </div>

      </div>


       <div class="row mt-100 d-md-flex align-items-center">

          <div class="col-md-6">
            <div class="row">
              <div class="col-md-8">
               <div class="work-proc-icon">
                <img src="assets/img/icons/Work-Process_3.png" alt="">
               </div>
              </div>

              <div class="col-md-4 text-center">
                <div class="work-process-count wow slideInDown">
                  <h4>03</h4>
                  <img src="assets/img/icons/arrow-right.png" alt="">
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="work-process-text wow zoomIn">
              <h4 class="block-title color-text mb-4">Testing & Bug Fixing</h4>
              <p>
                Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming vulputate. Nam liber tem por cum soluta nobis eleifend option congue nihil imper.
              </p>
            </div>
          </div>

        </div>


        <div class="row mt-100 d-md-flex align-items-center">

        <div class="col-md-6 flex-md-last">
          <div class="row d-md-flex">
            <div class="col-md-8 flex-md-last">
               <div class="work-proc-icon">
                <img src="assets/img/icons/Work-Process_4.png" alt="">
               </div>
            </div>

            <div class="col-md-4 text-center">
              <div class="work-process-count wow slideInDown">
                <h4>04</h4>
                <img src="assets/img/icons/arrow-left.png" alt="">
              </div>
            </div>
          </div>
        </div>

         <div class="col-md-6">
          <div class="work-process-text wow zoomIn">
            <h4 class="block-title color-text mb-4">Project Launch & Support</h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend option congue nihil imper per tem por legere me doming vulputate. Nam liber tem por cum soluta nobis eleifend option congue nihil imper.
            </p>
          </div>
        </div>

      </div>



    </div>
    <div class="process-bottom-img">
      <img src="assets/img/process-bottom-img.jpg" alt="">
    </div>
  </section>


  <section class="fun-fact">
    <div class="container">
      <div class="row">

        <div class="col-lg-3 col-sm-6 text-center mt-sm-4 wow zoomIn" data-wow-duration="1s" data-wow-delay=".1s">
          <div class="fun-fact-icon">
            <span class="lnr lnr-briefcase"></span>
          </div>
          <h4 class="fun-fact-count" data-from="0" data-to="2473" data-speed="1500">2,473</h4>
          <p class="fun-fact-title">projects completed</p>
        </div>

        <div class="col-lg-3 col-sm-6 text-center mt-sm-4 wow zoomIn" data-wow-duration="1s" data-wow-delay=".2s">
          <div class="fun-fact-icon">
            <span class="lnr lnr-smile"></span>
          </div>
          <h4 class="fun-fact-count" data-from="0" data-to="1250" data-speed="1000">1250</h4>
          <p class="fun-fact-title">happy client</p>
        </div>

        <div class="col-lg-3 col-sm-6 text-center mt-sm-4 wow zoomIn" data-wow-duration="1s" data-wow-delay=".3s">
          <div class="fun-fact-icon">
            <span class="lnr lnr-book"></span>
          </div>
          <h4 class="fun-fact-count" data-from="0" data-to="1357" data-speed="1000">1357</h4>
          <p class="fun-fact-title">positive feedback</p>
        </div>

        <div class="col-lg-3 col-sm-6 text-center mt-sm-4 wow zoomIn" data-wow-duration="1s" data-wow-delay=".4s">
          <div class="fun-fact-icon">
            <span class="lnr lnr-coffee-cup"></span>
          </div>
          <h4 class="fun-fact-count" data-from="0" data-to="17253" data-speed="1000">17253</h4>
          <p class="fun-fact-title">cups coffee</p>
        </div>

      </div>
    </div>
  </section>






  <section class="clients bg-gray">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="client-logo wow slideInUp" data-wow-duration="1s" data-wow-delay=".1s">
          <img src="assets/img/client/1.png" alt="">
        </div>
        <div class="client-logo wow slideInUp" data-wow-duration="1s" data-wow-delay=".2s">
          <img src="assets/img/client/2.png" alt="">
        </div>
        <div class="client-logo wow slideInUp" data-wow-duration="1s" data-wow-delay=".3s">
          <img src="assets/img/client/3.png" alt="">
        </div>
        <div class="client-logo wow slideInUp" data-wow-duration="1s" data-wow-delay=".4s">
          <img src="assets/img/client/4.png" alt="">
        </div>
        <div class="client-logo wow slideInUp" data-wow-duration="1s" data-wow-delay=".5s">
          <img src="assets/img/client/5.png" alt="">
        </div>
      </div>
    </div>
  </section>




  <section class="call-to-action bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h2 class="section-title">Inicia tu poyecto conmigo!</h2>
            <p></p>
            <a [routerLink]="['/contacto']" class="btn btn-theme btn-active mt-2">contactame</a>
          </div>
        </div>
      </div>
    </section>
  </main>

<app-footer></app-footer>
