<br />

<app-navbar></app-navbar>

<main class="content-wrap">
    <section>
      <div class="container">

        <div class="row">
         <div class="col-sm-12 text-center mb-5">
           <h2 class="section-title">
             Single Post
           </h2>
           <span class="background-text">blog</span>
         </div>
        </div>

        <div class="row">

          <div class="col-lg-8 mt-4">

            <article class="blog-details">
              <div class="post-img">
                <img src="assets/img/blog/l1.jpg" alt="">
              </div>
             <div class="post-content">
                <h2 class="post-title">
                  How to Improve Your Design!
                </h2>
              <div class="post-meta">
                Saifullah  |  9 Dec 2016
              </div>
                <p>
                  Nam liber tempor cum soluta nobis is eleifend option congue nihil imper per tem por legere me doming vulputate. Ipsum is simply dummy text of the and typesetting industry. Lorem Ipsum is has been the industry’s stasn ndard dummy text ever since the 1500s, when an unknown printer took a galley of it to make. <a href="#">Lorem Ipsum is the simply dummy </a> text of the printing and typesetting industry. Lorem Ipsum has been the indus try’s standard they dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived.
                </p>
                <blockquote>
                  Nam liber tempor cum soluta nobis is eleifend option congue nihil imper per tem por legere me doming vulputate. Ipsum is simply dummy text of the and typesetting industry
                </blockquote>
                <h4>We just know now gonna make</h4>
                <img src="assets/img/blog/l2.jpg" alt="">
                <ol>
                  <li>Nam liber tempor cum soluta nobis</li>
                  <li>industry’s stasn ndard dummy</li>
                  <li>Nam liber tempor cum soluta nobis is eleifend option congue</li>
                  <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                </ol>
                <p>
                  Nam liber tempor cum soluta nobis is eleifend option congue nihil imper per tem por legere me doming vulputate. Ipsum is simply dummy text of the and typesetting industry. Lorem Ipsum is has been the industry’s stasn ndard dummy text ever since the 1500s.
                </p>
              </div>
            </article>

            <div class="post-share-and-taq">
              <div class="row">
                <div class="col-md-6">
                  <div class="share-post">
                    <p>
                      <span>Share this post :</span>
                      <a href="#" class="color-social"><i class="fa fa-facebook"></i></a>
                      <a href="#" class="color-social"><i class="fa fa-twitter"></i></a>
                      <a href="#" class="color-social"><i class="fa fa-google-plus"></i></a>
                      <a href="#" class="color-social"><i class="fa fa-linkedin"></i></a>
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="post-taq text-right">
                    <a href="#">#Design</a>
                    <a href="#">#Branding</a>
                    <a href="#">#Development</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="theme-pagination py-5">
              <ul class="list-inline clearfix">
                <li class="pull-left">
                  <a href="#"><span class="lnr lnr-arrow-left"></span> Previous</a>
                </li>
                <li class="pull-right">
                  <a href="#">Next <span class="lnr lnr-arrow-right"></span></a>
                </li>
              </ul>
            </div>

            <div class="ec-comments-area comments-area" id="comments">
              <div class="comments-section">
                <h3 class="comments-title">2 Comments</h3>
                <ol class="comments list-inline">

                  <li class="comment">
                      <div class="comments-reply">
                          <a rel="nofollow" class="comment-reply-link" href="#0">
                            <span class="comment-reply-link">Reply</span>
                          </a>
                        </div>
                      <div class="comment-image">
                        <img src="assets/img/author/5.jpg" alt="">
                      </div>
                      <div class="comment-main-area">
                          <div class="ec-comments-meta">
                            <h4>Jhon Methov</h4>
                            <span class="says">Says</span>
                            <span class="comments-date">August 15, 2016 at 11:33</span>
                          </div>
                          <div class="comment-content">
                            <p>Accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo marvl inven tore veritaet quasi archi tecto beatae vitae dicta sunt explicabo. Bear claw marzi pan gingerbread pastry tootsie roll.</p>
                          </div>
                      </div>
                  </li>

                    <ul class="children">
                      <li class="comment odd alt">
                          <div class="comments-reply">
                              <a rel="nofollow" class="comment-reply-link" href="#0">
                                <span class="comment-reply-link">Reply</span>
                              </a>
                            </div>
                          <div class="comment-image">
                            <img src="assets/img/author/4.jpg" alt="">
                          </div>
                          <div class="comment-main-area">
                              <div class="ec-comments-meta">
                                <h4>Arwen</h4>
                                <span class="says">Says</span>
                                <span class="comments-date">August 15, 2016 at 11:33</span>
                              </div>
                              <div class="comment-content">
                                <p>Accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo marvl inven tore dicta sunt explicabo..</p>
                              </div>
                          </div>
                      </li>
                    </ul>


                  <li class="comment">
                    <div class="comments-reply">
                      <a rel="nofollow" class="comment-reply-link" href="#0">
                        <span class="comment-reply-link">Reply</span>
                      </a>
                    </div>
                    <div class="comment-image">
                      <img src="assets/img/author/3.jpg" alt="">
                    </div>
                    <div class="comment-main-area">
                        <div class="ec-comments-meta">
                          <h4>Jhon Methov</h4>
                          <span class="says">Says</span>
                          <span class="comments-date">August 15, 2016 at 11:33</span>
                        </div>
                        <div class="comment-content">
                          <p>Accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo marvl inven tore veritaet quasi archi tecto beatae vitae dicta sunt explicabo. Bear claw marzi pan gingerbread pastry tootsie roll.</p>
                        </div>
                    </div>
                  </li>
                </ol>
              </div>
            </div>

            <div id="respond" class="ec-comment-form comment-respond">

              <h3 id="reply-title" class="comment-reply-title">Leave Your Comments
                <small>
                  <a rel="nofollow" id="cancel-comment-reply-link" href="#respond">
                    <span class="icon-close"></span>
                  </a>
                </small>
              </h3>

              <form novalidate="" method="post" id="commentform" class="comment-form" action="#0">
                <div class="row ec-form-inputs">
                <div class="col-sm-4">
                  <input placeholder="Name" type="tel">
                </div>
                <div class="col-sm-4">
                  <input placeholder="Email" type="tel">
                </div>
                <div class="col-sm-4">
                  <input placeholder="Website" type="tel">
                </div>
                </div>
                <div class="ec-form-textarea">
                  <textarea id="comment" rows="5" placeholder="Your text here..."></textarea>
                </div>
                <div class="form-submit">
                  <button class="btn btn-theme btn-active">Submit Now</button>
                </div>
              </form>
            </div>
          </div>


            <aside id="sidebar" class="col-lg-4 mt-4">

            <div class="sidebar-widget">
              <div class="post-tabs">

                 <div class="post-tab-list clearfix">
                   <a href="#tab-1" data-tab="tab-1" class="active">recent posts</a>
                   <a href="#tab-2" data-tab="tab-2">popular posts</a>
                 </div>

                  <div class="widget-content">
                    <div id="tab-1" class="tab-content active">

                      <div class="side-post-block clearfix">
                        <div class="side-post-img">
                          <a href="#">
                            <img src="assets/img/blog/1.jpg" alt="">
                          </a>
                        </div>
                        <div class="side-post-content">
                          <h4><a href="#">Some words about my vacation</a></h4>
                          <span class="post-date">Feb 28, 2017</span>
                        </div>
                      </div>

                      <div class="side-post-block clearfix">
                        <div class="side-post-img">
                          <a href="#">
                            <img src="assets/img/blog/2.jpg" alt="">
                          </a>
                        </div>
                        <div class="side-post-content">
                          <h4><a href="#">Weeknight vegetable curry</a></h4>
                          <span class="post-date">June 14, 2017</span>
                        </div>
                      </div>

                      <div class="side-post-block clearfix">
                        <div class="side-post-img">
                          <a href="#">
                            <img src="assets/img/blog/3.jpg" alt="">
                          </a>
                        </div>
                        <div class="side-post-content">
                          <h4><a href="#">Can't wait to see</a></h4>
                          <span class="post-date">Aug 12, 2017</span>
                        </div>
                      </div>

                      <div class="side-post-block clearfix">
                        <div class="side-post-img">
                          <a href="#">
                            <img src="assets/img/blog/4.jpg" alt="">
                          </a>
                        </div>
                        <div class="side-post-content">
                          <h4><a href="#">Four places to eat Kabab</a></h4>
                          <span class="post-date">July 4, 2017</span>
                        </div>
                      </div>

                    </div>

                    <div id="tab-2" class="tab-content">

                      <div class="side-post-block clearfix">
                        <div class="side-post-img">
                          <a href="#">
                            <img src="assets/img/blog/5.jpg" alt="">
                          </a>
                        </div>
                        <div class="side-post-content">
                          <h4><a href="#">Four places to eat Kabab</a></h4>
                          <span class="post-date">Feb 28, 2017</span>
                        </div>
                      </div>

                      <div class="side-post-block clearfix">
                        <div class="side-post-img">
                          <a href="#">
                            <img src="assets/img/blog/6.jpg" alt="">
                          </a>
                        </div>
                        <div class="side-post-content">
                          <h4><a href="#">Can't wait to see</a></h4>
                          <span class="post-date">June 14, 2017</span>
                        </div>
                      </div>

                      <div class="side-post-block clearfix">
                        <div class="side-post-img">
                          <a href="#">
                            <img src="assets/img/blog/1.jpg" alt="">
                          </a>
                        </div>
                        <div class="side-post-content">
                          <h4><a href="#">Weeknight vegetable curry</a></h4>
                          <span class="post-date">Aug 12, 2017</span>
                        </div>
                      </div>

                      <div class="side-post-block clearfix">
                        <div class="side-post-img">
                          <a href="#">
                            <img src="assets/img/blog/2.jpg" alt="">
                          </a>
                        </div>
                        <div class="side-post-content">
                          <h4><a href="#">Some words about my vacation</a></h4>
                          <span class="post-date">July 4, 2017</span>
                        </div>
                      </div>

                    </div>
                  </div>
              </div>
            </div>

            <div class="sidebar-widget">
              <div class="newsletter-widget">
                 <h2 class="sidebar-widget-title">Newsletter</h2>
                <div class="widget-content">
                  <span class="ns-icon">
                    <i class="fa fa-envelope-o"></i>
                  </span>
                  <h5>Subscribe to my Newsletter</h5>
                  <p>Recieve updates and news via email.</p>
                  <form action="#" method="">
                    <input type="text" placeholder="Enter your email">
                    <button class="btn btn-theme btn-active btn-block">subscribe now</button>
                  </form>
                </div>
             </div>
            </div>

            <div class="widget sidebar-widget">
               <h2 class="sidebar-widget-title">Categories</h2>
              <div class="widget-content">
                <ul>
                  <li><a href="#">Branding / Design </a></li>
                  <li><a href="#">Marketing</a></li>
                  <li><a href="#">Uncategorized</a></li>
                  <li><a href="#">Branding / Design</a></li>
                  <li><a href="#">Marketing</a></li>
                </ul>
              </div>
            </div>

            <div class="sidebar-widget">
              <div class="gallary-widget">
                 <h2 class="sidebar-widget-title">Instagram gallery</h2>
                <div class="widget-content">
                  <ul class="list-inline sidebar-gallary clearfix">
                    <li><a href="#"><img src="assets/img/blog/1.jpg" alt=""></a></li>
                    <li><a href="#"><img src="assets/img/blog/2.jpg" alt=""></a></li>
                    <li><a href="#"><img src="assets/img/blog/3.jpg" alt=""></a></li>
                    <li><a href="#"><img src="assets/img/blog/4.jpg" alt=""></a></li>
                    <li><a href="#"><img src="assets/img/blog/5.jpg" alt=""></a></li>
                    <li><a href="#"><img src="assets/img/blog/6.jpg" alt=""></a></li>
                    <li><a href="#"><img src="assets/img/blog/1.jpg" alt=""></a></li>
                    <li><a href="#"><img src="assets/img/blog/2.jpg" alt=""></a></li>
                    <li><a href="#"><img src="assets/img/blog/3.jpg" alt=""></a></li>
                    <li><a href="#"><img src="assets/img/blog/4.jpg" alt=""></a></li>
                    <li><a href="#"><img src="assets/img/blog/5.jpg" alt=""></a></li>
                    <li><a href="#"><img src="assets/img/blog/6.jpg" alt=""></a></li>
                  </ul>
                </div>
             </div>
            </div>


            <div class="sidebar-widget">
              <div class="adds-widget">
                <a href="#"><img src="assets/img/blog/l2.jpg" alt=""></a>
             </div>
            </div>


            <div class="sidebar-widget">
              <div class="tag-cloud">
                <h2 class="sidebar-widget-title">popular tags</h2>
                <div class="widget-content tags-list">
                  <a href="#">Fashion</a>
                  <a href="#">Audio</a>
                  <a href="#">Lifestyle</a>
                  <a href="#">Travel</a>
                  <a href="#">Food</a>
                  <a href="#">Products</a>
                  <a href="#">Education</a>
                  <a href="#">envato</a>
                  <a href="#">UI UX</a>
                  <a href="#">WordPress</a>
                  <a href="#">Blogging</a>
                </div>
             </div>
            </div>

          </aside>

        </div>
      </div>
    </section>

    </main>

<app-footer></app-footer>
