<div class="container">

    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="795.9" height="433" viewBox="0 0 795.9 433">
    
    <g class="page-fall2">
      <path class="white_page fade7" d="M0 377.8h60.9s9.330.2 3.3 55.1H0v-55.1z"/>
    </g>
  
    <g class="page-fall3">
      <path class="dark_gray_page fade8" d="M3.9 292.3l51-28.5s5 20.5 0 46-7.5 58.5-7.5 58.5l-24.5 25s-10-24-11.5-62-7.5-39-7.5-39z"/>
    </g>
  
    <g class="page-fall2">
      <path class="white_page fade7" d="M11.4 174.3s11.3-19.5-11.4-70v-29l22.4-20s26 61 19.5 98.5l-30.5 20.5z"/>
    </g>
  
    <g class="page-fall2">
      <path class="dark_gray_page fade7" d="M48.4 106.9l31-46.1s26-25 41.5-12.5l-19 27.5c0 .1-29-5.8-53.5 31.1zM236.2 429l51.5-21.5s-6.5-74-51.5-87.5l-33.5 33.5s29 37.5 33.5 75.5z"/>
    </g>
  
    <g class="page-fall3">
      <path class="white_page fade8" d="M297.5 421.6l32-21.5s9-13.5 0-33l37-33.8s14 21.2 0 40.2c0 .1-58.5 48.6-69 48.1z"/>
    </g>
  
    <g class="page-fall3">
      <g class="fade8">
        <path class="dark_gray_page" d="M392.4 165.6l63 68.5s-50.5 48-63 71.5l-45.5-71.5s-8-21 45.5-68.5z"/>
  
        <path class="white_page" d="M403.9 333.1s-7-1-10.5-5.5-46.5-93.5-46.5-93.5 6.5 6.5 32 4l25 95zM510.4 433s-1.5-39.1-3-41.1c0 0 64.5-9.5 87 11.5l6 29.6h-90z"/>
      </g>
    </g>
  
    <g class="page-fall2">
      <path class="dark_gray_page fade7" d="M486.9 274l49.2-49s52.4 49 47.5 76l-70.1 15c-.1 0-10.8-22.5-26.6-42z"/>
    </g>
  
    <g class="page-fall2">
      <path class="white_page fade7" d="M501.5 319.5l11.9 11.1 65.7-28.6zM603.5 260.8l83.3 8.5s22.7 53.2 15.3 102.5h-86.7c.1 0 .1-86.6-11.9-111z"/>
    </g>
  
    <g class="page-fall2">
      <path class="white_page fade7" d="M502 129.2l61.5 19.3s-3.7 12.7 0 16c3.7 3.3 1.7 28 0 31.3h-67c0 .1 2.3-62.6 5.5-66.6zM749.5 296.8l3.3 99s20-8.3 43-6.1v-92.9c.1 0-18.9-3.6-46.3 0z"/>
    </g>
  
    <g class="page-fall2">
      <path class="medium_gray fade7" d="M795.9 103.8h-34.3s8.7 14.7 8 32c-.7 17.3 3.2 85.5 3.2 85.5H796V103.8z"/>
    </g>
  
    <g id="left-martini-page"> 
      <g class="fade5">
        <path class="medium_gray" d="M135.8 329.2s64-66.5 72-91.5l63 32.5s-37.5 74.5-67.5 101c0 0-51.5-40.5-67.5-42z"/>
  
        <ellipse transform="rotate(-52.468 180.18 333.917)" class="light_orange" cx="180.2" cy="333.9" rx="2.7" ry="17.1"/>
  
        <path class="orange" d="M186.2 333.7c.2 1.6 2.7 6.2 2.4 6.1-4.3-1.2-15.5-11.2-16.5-12.1.7.5 4.8.8 6.4.7 1.7-.2 3-.7 5.9-3.3 12.4-11.6 20.5-28.8 20.5-28.8l5.8 4.4s-14.6 11.6-22.6 26.5c-2 3.4-2.1 4.8-1.9 6.5z"/>
  
        <path class="top-martini" d="M248.1 289.4l-8.8 2.7v-.1L205 265.7v-9.4z"/>
  
        <path class="dark_navy" d="M225.4 288.3c-.1-.1-.2-.2-.3-.4-.2-.5.1-1 .5-1.2l30-10.9c.5-.2 1 .1 1.2.5.2.5-.1 1-.5 1.2l-30 10.9c-.3.1-.6 0-.9-.1z"/>
  
        <ellipse transform="rotate(-61.387 246.644 279.667)" class="green" cx="246.6" cy="279.7" rx="4.7" ry="4.7"/><path class="dark_navy" d="M239.3 292.1l-28.8 8.6-5.7-4.4.2-30.6 34.3 26.3z"/>
      </g>
    </g>
  
    <g id="hotel-top">
  
      <g class="fade8">
        <path class="medium_gray" d="M249.8 28.7s37.3 144.7 35.3 160c0 0 91.4-30.7 107.7-41.3C409.1 136.7 367.3 2 367.3 2L249.8 28.7z"/>
  
        <path transform="rotate(-19.377 320.51 103.564)" class="dark_navy" d="M305.8 73.5h29.6v60.1h-29.6z"/>
  
        <path transform="rotate(-19.377 327.877 124.515)" class="med-blue" d="M322.1 116.7h11.7v15.6h-11.7z"/>
  
        <path transform="rotate(-19.377 307.358 83.63)" class="light-blue" d="M303.7 79.9h7.5v7.5h-7.5z"/>
  
        <path transform="rotate(-19.377 318.288 79.786)" class="light-blue" d="M314.6 76.1h7.5v7.5h-7.5z"/>
  
        <path transform="rotate(-19.377 310.821 93.478)" class="light-blue" d="M307.1 89.7h7.5v7.5h-7.5z"/>
  
        <path transform="rotate(-19.377 321.75 89.634)" class="light-blue" d="M318.1 85.9h7.5v7.5h-7.5z"/>
  
        <path transform="rotate(-19.377 314.284 103.325)" class="light-blue" d="M310.6 99.6h7.5v7.5h-7.5z"/>
  
        <path transform="rotate(-19.377 325.214 99.482)" class="light-blue" d="M321.5 95.8h7.5v7.5h-7.5z"/>
  
        <path transform="rotate(-19.377 317.747 113.173)" class="light-blue" d="M314.1 109.4h7.5v7.5h-7.5z"/>
  
        <path transform="rotate(-19.377 328.677 109.33)" class="light-blue" d="M325 105.6h7.5v7.5H325z"/>
  
        <path transform="rotate(-19.377 310.27 74.448)" class="med-blue" d="M293 73.6h34.6v1.7H293z"/>
  
        <path transform="rotate(-19.377 309.652 72.69)" class="martini-stick" d="M290.1 71.5h39.1v2.3h-39.1z"/>
  
        <path transform="rotate(-19.377 353.367 103.529)" class="orange" d="M337.2 84.4h32.5v38.3h-32.5z"/>
  
        <path transform="rotate(-19.377 344.165 77.359)" class="light_orange" d="M331.5 68.8h25.4V86h-25.4z"/>
  
        <g>
          <path transform="rotate(-19.377 363.922 111.062)" class="light_orange" d="M359.9 102.5h8.2v17.1h-8.2z"/>
  
          <path transform="rotate(-19.377 342.739 95.79)" class="extra-light-o" d="M338.7 91.7h8.2v8.2h-8.2z"/>
  
          <path transform="rotate(-19.377 346.634 106.865)" class="extra-light-o" d="M342.6 102.8h8.2v8.2h-8.2z"/>
  
          <path transform="rotate(-19.377 350.342 117.41)" class="extra-light-o" d="M346.3 113.3h8.2v8.2h-8.2z"/><path transform="rotate(-19.377 356.798 90.803)" class="extra-light-o" d="M352.7 86.7h8.2v8.2h-8.2z"/>
        </g>
  
  
        <path transform="rotate(-19.377 341.078 68.58)" class="orange" d="M326.9 67.9h28.5v1.5h-28.5z"/>
  
        <g>
          <path transform="rotate(-19.377 338.561 79.33)" class="extra2-light-o" d="M335.1 75.9h7v7h-7z"/>
  
          <path transform="rotate(-19.377 349.768 75.389)" class="extra2-light-o" d="M346.3 71.9h7v7h-7z"/>
        </g>
  
        
        <path class="green" d="M285.9 62l2.3 2.4 3.3-.7-1.5 3 1.6 2.9-3.2-.5-2.3 2.4-.6-3.2-3-1.4 3-1.6zM296.8 51l2.3 2.4 3.3-.7-1.5 3 1.6 2.9-3.2-.5-2.3 2.5-.6-3.3-3-1.4 3-1.6zM312.1 43.9l2.4 2.3 3.2-.6-1.5 2.9 1.7 2.9-3.3-.4-2.3 2.4-.5-3.3-3.1-1.4 3-1.5zM327.4 40.3l2.3 2.3 3.3-.6-1.5 3 1.6 2.9-3.3-.5-2.2 2.4-.6-3.3-3-1.4 3-1.5zM343.7 42l2.4 2.3 3.3-.6-1.5 2.9 1.6 2.9-3.3-.4-2.2 2.4-.6-3.3-3-1.4 2.9-1.5z"/>
      </g>
    </g>
    <g id="spa-bottom">
      <g class="fade6">
        <path class="medium_gray" d="M276.9 274.8s21.3 54 26.7 58.3l57.1-54-40.1-47.3-43.7 43z"/>
  
        <path class="st6" d="M325.2 303c-2.9 1.4-8-2.4-15.7-12.7-7.8-10.5-4.9-17.9-4.2-18.5.1-.1.3-.2.5-.2 2.3-.8 8.8 2.4 13.9 10.5 1.2 1.9 9.1 18 6.3 20.5-.3.1-.5.2-.8.4z" id="XMLID_48_"/>
  
        <path class="navy-light" d="M324.5 292.6s-.1 0 0 0c-1.9-1.6-4-4.2-6-6.9-.8-1.2-1.7-2.4-2.4-3.6-2.2-3.5-4.1-7.3-5.3-10.4-1.1-2.9-1.5-5.1-.9-5.9.4-.5 1-.8 1.9-.6 1.2.3 2.8 1.3 5.1 3.6 1.8 1.8 3.9 4.4 6.4 8 0 0 4 5.8 5.8 10.6.4 1.2.7 2.3.9 3.2.1 1.1 0 2-.5 2.6-1.1 1.7-3 1.1-5-.6z" id="XMLID_47_"/>
  
        <path class="st6" d="M330.2 283.3c-.1.1-.3.2-.6.2-1.2.1-4-.9-8-7.5-3.4-5.7-4.6-8.1-4.8-9.2-.1-.7.2-1 .7-1.3.4-.3 1.5-.5 2.9-.2.9.2 1.9.5 3 1.1 2.1 1.1 4.5 3.2 6.6 6.7 3.1 5.2 2.1 8 1.1 9.3-.3.5-.7.8-.9.9z" id="XMLID_46_"/>
  
        <path class="navy-light" d="M333.1 278.1s0-.1 0 0c-1-.6-2-1.8-2.9-3-.4-.5-.7-1.1-1.1-1.6-1-1.7-1.7-3.5-2-5-.3-1.4-.3-2.6.2-3.1.3-.3.7-.5 1.3-.6.7 0 1.6.3 2.8 1.3.9.8 1.9 1.9 3.1 3.5 0 0 1.8 2.6 2.3 5.1.1.6.2 1.1.1 1.7-.1.6-.3 1.1-.7 1.5-.8.9-2 .8-3.1.2z" id="XMLID_45_"/>
  
        <path class="extra-light-o" d="M328.4 272.2s4.4.9 4.6 3.4c0 .6-1.5.5-1.7.4-.2 0-1.4-.5-2.1-2.2-.6-1.4-.8-1.6-.8-1.6z"/>
  
        <path class="extra-light-o" d="M330.1 270.8s2.2-1.8 3.1-.6c.4.5-.9 1.9-1.3 2.1-.8.3-2.6.4-3.4-.2l1.6-1.3z"/>
  
        <path class="extra-light-o" d="M328.2 271.9s2.3-.6 2.1-2.3c-.2-1.7-1.3-2.1-1.3-2.1s-2 2.2-.8 4.4z"/>
  
        <path class="extra-light-o" d="M328.2 271.8s-2.3-2.3-4.1-.5c-.6.6 2.5 2 3.8 1.2.4-.1.3-.7.3-.7z"/>
  
        <path class="extra-light-o" d="M328.3 271.9l.4 1.3s0 3.2-1.8 3.7c-.9.3-.4-2.5-.2-3 .2-.3 1.5-1.3 1.6-2z"/>
  
        <path class="orange" d="M328.3 271.6s.4-.9.3-1c0-.1.4.9.4.9s1 .1 1 0c.1-.1-.8.5-.8.5-.1 0 .4.8.4.8l-.9-.6-.4.5-.1-.7-.5-.3.6-.1z"/>
  
        <g>
          <g id="XMLID_44_">
            <path class="green" d="M300.8 274.8c-.1-.1-.1-.1 0 0 0-.1 0-.1 0 0zM305.6 283.6c-.1-.2-.2-.3-.3-.5.1.2.2.4.3.5z"/>
          </g>
  
          <g id="XMLID_43_">
            <path class="green" d="M320.3 304.9s-7-8.6-7.5-9.7l-.3-.4c0-.1-.5-.9-.5-.9-1.1-1.9-2.7-3.5-5.3-7.5-.4-.6-.9-1.4-1.4-1.9-.2-.2-.5-.4-.7-.7-.9-.9-1.8-2-2.6-3.2-2.4-3.5-4.2-7.8-4-9.8 0 0 1.3 1.8 2.4 3.5.1.1.2.3.3.4v.1c.7 1 1.3 1.8 1.5 2.1.3.4 1.4 3 2.2 4.7.2.4.3.7.4.9.1.2.2.4.4.6 0 0 0 .1.1.1.1.2.2.3.3.5v.1c.3.4.6.9 1 1.4.6.8 1.2 1.5 1.8 2.2.8.9 3.8 5.4 4.2 5.9.1.1.4.7.9 1.4 1 1.6 6.7 9.4 7.5 9.8l-.7.4z"/>
  
            <path class="light-green" d="M306.6 285.1c-.3-.5-.7-.9-1-1.4v-.1c-.1-.2-.2-.3-.3-.5 0 0 0-.1-.1-.1-.1-.2-.3-.4-.4-.6-.1-.2-.3-.6-.4-.9-.8-1.7-1.9-4.3-2.2-4.7-.2-.3-.8-1.1-1.5-2.1v-.7-.8-1.7c0-.7 0-1.4-.1-1.8v-.9c.1-.5-.2-3.6 0-3.9 0 0 .3 3.3 1.1 4.9.1.3.3.5.5.6.6.5 2.8 3.6 4.2 6.6.4.9.3 1.9.1 2.7-.2.7-.4 1.3-.5 1.8v.2c0 .3.2 1.3.3 2.3.2.4.2.8.3 1.1z"/>
  
          </g>
        </g>
  
        <g>
          <path class="extra-light-o" d="M321.6 291.3s5.8-1.7 7.6 1.2c.4.7-1.5 1.5-1.8 1.6-.3.1-2 .2-3.9-1.3-1.5-1.3-1.9-1.5-1.9-1.5z"/>
  
          <path class="extra-light-o" d="M322.8 288.6s1.5-3.6 3.3-2.7c.8.4.2 2.8-.3 3.4-.8.8-2.8 2.1-4.2 1.9l1.2-2.6z"/>
  
          <path class="extra-light-o" d="M321.2 291.1s2.4-2.1 1.1-4c-1.4-1.9-2.9-1.7-2.9-1.7s-1 3.9 1.8 5.7z"/>
  
          <path class="extra-light-o" d="M321.3 291s-4.2-1.3-5.2 2c-.3 1.1 4.2.8 5.3-.9.3-.5-.1-1.1-.1-1.1z"/>
  
          <path class="extra-light-o" d="M321.3 291.1l1.3 1.2s2 3.8.2 5.6c-.9.9-2.1-2.8-2.1-3.5.1-.4 1-2.5.6-3.3z"/>
  
          <path class="orange" d="M321.2 290.7s-.1-1.3-.2-1.4c-.1-.1 1.1.8 1.1.8 0 .1 1.2-.5 1.2-.7 0-.2-.6 1.1-.7 1.2-.1.1 1 .8 1 .8l-1.5-.1-.1.9-.5-.8h-.8l.5-.7z"/>
  
        </g>
      </g>
    </g>
  
    <g id="chemistry-big">
  
      <path class="white_page" d="M134.1 236.5l128.4-40.7s-5.7 10.5-11.6 12.7c-5.9 2.2-92.7 34.8-92.7 34.8s-14.9-2.1-24.1-6.8z"/>
  
      <path class="dark_gray_page" d="M125.3 145.8l58.9-85.3s128 102 66.7 148l-76 28s28.1-24-49.6-90.7z"/>
  
      <path class="orange" d="M214.8 131.6c.4.6.3 1.4-.3 1.9l-10 7c-.6.4-1.4.3-1.9-.4l-.2-.2c-.4-.6-.3-1.4.3-1.9l10-7c.6-.4 1.4-.3 1.9.4l.2.2z"/>
  
      <g id="XMLID_50_">
        <path class="dark_navy" d="M222.4 144.2c4.9 7.1 11 15.7 13.8 19.7 1.8 2.6 1.5 6-1.7 8.3l-.5.3c-3.1 2.2-6.5 1.4-8.3-1.2-2.7-3.9-8.4-12-13.2-18.9 0 0 1.1-4.7 4.5-5.6 4.1-1.2 5.4-2.6 5.4-2.6z"/>
  
        <path class="light_orange" d="M214.7 133.3s3.4 4.9 7.6 10.9c0 0-1.3 1.4-5.4 2.6-3.4 1-4.5 5.6-4.5 5.6-4.5-6.4-8.2-11.8-8.2-11.8l10.5-7.3z"/>
  
      </g>
  
      <g>
        <path class="orange" d="M187.5 133.4c.4.6.2 1.4-.4 1.8l-14.5 10.2c-.6.4-1.4.3-1.8-.2l-.1-.1c-.4-.6-.2-1.4.4-1.8l14.5-10.2c.6-.4 1.4-.3 1.8.2l.1.1z"/>
  
        <g id="XMLID_49_">
  
  
          <path class="med-blue" d="M230.5 169.3s4.1 2.6.2 5.3l-35.8 25.1c-3.9 2.7-5-2-5-2l-3.4-24.6s3.1-6.3 8.7-8.5c7.8-3.1 7.1-10.1 7.1-10.2l28.2 14.9z"/>
  
          <path class="light_orange" d="M202.3 154.5c0 .1.8 7.1-7.1 10.2-5.6 2.2-8.7 8.5-8.7 8.5l-1.5-11.3-11.8-16.9 13.4-9.4 11.8 16.9 3.9 2z"/>
  
        </g>
  
        <ellipse transform="rotate(-35.011 198.16 185.684)" class="light-bubbles" cx="198.2" cy="185.7" rx="5" ry="5"/>
  
        <ellipse transform="rotate(-35.011 199.115 174.96)" class="light-bubbles" cx="199.1" cy="175" rx="2.9" ry="2.9"/>
  
        <ellipse transform="rotate(-35.011 205.544 179.716)" class="light-bubbles" cx="205.5" cy="179.7" rx="2.1" ry="2.1"/>
  
      </g>
    </g>
  
  
    <g id="comp-big">
  
      <g class="fade8">
  
        <path class="medium_gray" d="M600.1 231.8l10.8-130.7 130.7 9.3s-28.7 160-34.7 165.3l-106.8-43.9z"/>
  
        <path class="da" d="M699.1 200.3c-.2 1.1-1.2 1.9-2.2 1.6l-62.5-13.5c-1-.2-1.6-1.3-1.4-2.4l10.1-46.5c.2-1.1 1.2-1.9 2.2-1.6l62.5 13.5c1 .2 1.6 1.3 1.4 2.4l-10.1 46.5z"/>
  
        <path transform="rotate(-77.79 671.109 169.92)" class="light-blue" d="M649.5 139.9h43.3v60h-43.3z"/>
  
        <path class="med-blue" d="M695.7 200.6l14.2 3.1-.8 3.8s-3 1.8-4.6 1.5l-80.9-17.5c-1.6-.3-3.6-3.2-3.6-3.2l.8-3.8 14.2 3.1 60.7 13z"/>
  
        <g>
  
          <path class="light-green" d="M665.7 161c-.2.4-.5.7-.7 1.1l-5.3-1.1c.3-.4.6-.7 1-1.1l5 1.1zM671.6 162.3l-.3 1.2-5-1.1c.2-.4.5-.8.7-1.1l4.6 1zM677.2 164.8l-4.6-1 .3-1.2 4.2.9c0 .4.1.8.1 1.3zM684.2 166.3l-5.7-1.2c0-.5-.1-.9-.1-1.3l5.4 1.2c.1.3.3.8.4 1.3z"/>
  
          <g>
            <path class="light-green" d="M662.2 176.1l-4.8-1c-.2-.5-.3-.9-.4-1.4l5.2 1.1c-.1.4 0 .8 0 1.3zM668.6 176.2l-.3 1.2-4.8-1c-.1-.4-.1-.9-.2-1.3l5.3 1.1zM669.9 176.4l4.8 1c-.2.4-.4.8-.7 1.1l-4.4-1 .3-1.1zM681.6 179c-.3.4-.6.7-1 1.1l-5.3-1.1c.2-.4.4-.8.7-1.1l5.6 1.1z"/>
  
          </g>
  
          <g>
  
            <path class="light-green" d="M662.5 168.9l-5.8-1.3c0-.2.1-.4.1-.6 0-.2.1-.4.1-.6l5.9 1.3-.3 1.2zM670.1 169.3l-.3 1.2-6.1-1.3.3-1.2 6.1 1.3zM676.8 172l-5.7-1.2.3-1.2 5.7 1.2-.3 1.2zM684.4 173c0 .2-.1.4-.1.6l-6.3-1.4c.1-.4.2-.8.2-1.2l6.3 1.4c0 .2 0 .4-.1.6z"/>
          </g>
  
          <g>
  
            <path class="green" d="M673.9 155c-8.3-1.8-16.5 3.5-18.3 11.8s3.5 16.5 11.8 18.3c8.3 1.8 16.5-3.5 18.3-11.8s-3.5-16.5-11.8-18.3zm-16.5 20c-.2-.5-.3-.9-.4-1.4-.5-1.9-.6-3.9-.3-6 0-.2.1-.4.1-.6 0-.2.1-.4.1-.6.5-2.1 1.5-3.9 2.8-5.5.3-.4.6-.7 1-1.1 2.4-2.3 5.6-3.8 9-4-1 1-2.6 2.7-4 5.1-.2.4-.5.7-.7 1.1-.1.2-.3.5-.4.8-.7 1.4-1.4 3-1.9 4.8l-.3 1.2c-.4 1.8-.5 3.7-.4 5.8 0 .4.1.9.1 1.3.2 2 .8 4.2 1.7 6.4-2.9-1.4-5.2-4.1-6.4-7.3zm9.6 8.7c-.5-.1-.9-.3-1.3-.4-1.1-2.3-1.8-4.6-2.1-6.9-.1-.4-.1-.9-.2-1.3-.2-2 0-3.9.4-5.8l.3-1.2c.4-1.5 1-3 1.7-4.5.2-.4.4-.7.6-1.1.2-.4.5-.8.7-1.1 1.9-2.9 3.9-4.8 4.5-5.3.5 0 .9.1 1.4.2l-6 27.4zm1.2.3l6-27.6.9.3c.3.6 1.4 3.2 2 6.8.1.4.1.9.2 1.3 0 .4.1.8.1 1.2.1 1.7 0 3.3-.3 4.8-.1.4-.1.8-.2 1.2-.4 1.9-1.1 3.7-2.1 5.5-.2.4-.4.8-.7 1.1-1.3 2-2.9 3.8-4.9 5.5-.4 0-.7-.1-1-.1zm13.4-5c-.3.4-.6.7-1 1.1-2.5 2.5-6 4-9.6 4.1 1.8-1.7 3.3-3.5 4.3-5.3.2-.4.4-.8.7-1.1 1-1.9 1.6-3.7 2-5.5.1-.4.2-.8.2-1.2.3-1.9.4-3.6.3-5.2 0-.3 0-.6-.1-.8 0-.5-.1-.9-.1-1.3-.4-2.8-1.1-5.1-1.6-6.4 3.3 1.6 5.7 4.3 7 7.6.2.5.3.9.5 1.4.5 1.9.7 4 .3 6.1 0 .2-.1.4-.1.6 0 .2-.1.4-.1.6-.6 1.9-1.5 3.7-2.7 5.3z"/>
  
          </g>
  
        </g>
      </g>
    </g>
  
  <g id="top-spa">
    <g class="fade4">
        <path class="dark_gray_page" d="M560.5 42l62.4-29.5s32.5 72.5 34 73.5-74.5 9-74.5 9l-21.9-53z"/>
  
        <path class="white_page" d="M582.4 95.3l3.5-13 67.7 4.8z"/>
  
        <path class="dark_navy" d="M629.1 55.9c.6 3.3-4.4 7.2-16.5 12.1-12.3 5-18.8.3-19.2-.6-.1-.2-.1-.3-.1-.5-.2-2.5 4.6-8 13.8-10.9 2.2-.7 20-4.4 21.7-1l.3.9z" id="XMLID_56_"/>
  
        <path class="navy-light" d="M618.7 54c-2.1 1.5-5.1 2.9-8.3 4.1-1.4.5-2.8 1-4.1 1.4-4.1 1.3-8.3 2.2-11.6 2.5-3.1.3-5.4.1-6-.6-.4-.5-.5-1.2 0-2 .5-1.1 2-2.5 4.8-4.1 2.2-1.3 5.3-2.7 9.5-4.3 0 0 6.7-2.5 11.9-3 1.2-.1 2.4-.2 3.4 0 1.1.2 2 .5 2.5 1.2 1.2 1.5.1 3.2-2.1 4.8z" id="XMLID_55_"/>
  
        <path class="dark_navy" d="M611 45.9c.1.1.1.3 0 .6-.2 1.2-1.8 3.7-9.4 5.9-6.5 1.9-9.1 2.5-10.3 2.4-.8-.1-.9-.5-1.1-1-.2-.5-.1-1.6.5-2.9.4-.8 1-1.7 1.8-2.7 1.6-1.8 4.3-3.6 8.3-4.8 5.9-1.8 8.4 0 9.4 1.3.6.6.8 1.1.8 1.2z" id="XMLID_54_"/>
  
        <path class="navy-light" d="M606.6 41.8c-.9.9-2.2 1.6-3.7 2.1-.6.2-1.3.5-1.9.6-1.9.5-3.8.8-5.4.7-1.5-.1-2.6-.4-3-1-.2-.4-.3-.9-.2-1.4.2-.7.7-1.5 2-2.4 1-.7 2.3-1.4 4.3-2.1 0 0 3-1.1 5.6-1 .6 0 1.2.1 1.7.3.6.2 1 .5 1.3 1 .6 1.2.3 2.3-.7 3.2z" id="XMLID_53_"/>
  
        <path class="extra-light-o" d="M599.6 45s2-4.1 4.5-3.6c.6.1.1 1.6 0 1.8-.1.2-.9 1.2-2.7 1.5-1.5.1-1.8.3-1.8.3z"/>
  
        <path class="extra-light-o" d="M598.7 42.9s-1.2-2.7.2-3.2c.6-.2 1.7 1.3 1.7 1.9.1.8-.2 2.6-1.1 3.3l-.8-2z"/>
  
        <path class="extra-light-o" d="M599.4 45.1s0-2.4-1.7-2.6c-1.7-.2-2.4.7-2.4.7s1.6 2.5 4.1 1.9z"/>
  
        <path class="extra-light-o" d="M599.3 45s-2.8 1.7-1.5 3.9c.5.8 2.6-1.9 2.2-3.4-.2-.4-.7-.5-.7-.5z"/>
  
        <path class="extra-light-o" d="M599.4 45l1.3-.1s3.1.8 3.2 2.7c0 .9-2.6-.2-3-.6-.2-.2-.9-1.7-1.5-2z"/>
        
        <path class="orange" d="M599.1 44.9s-.8-.6-.9-.6c-.1 0 1-.2 1-.2s.3-.9.3-1c-.1-.1.3.9.3 1 0 .1.9-.1.9-.1l-.8.8.4.5-.7-.1-.4.4-.1-.7z"/>
  
        <g>
          <g id="XMLID_52_">
            <path class="green" d="M595.2 72.7c-.1 0-.1 0 0 0-.1 0 0 0 0 0zM605.1 70.2s0 .1 0 0c-.2.1-.4.1-.6.2.2 0 .4-.1.6-.2z"/>
          </g>
  
          <g id="XMLID_51_">
            <path class="green" d="M629.7 61.2s-10.3 4.7-11.4 4.9l-.5.2c-.1 0-1 .3-1 .3-2.2.6-4.1 1.7-8.7 3.3-.7.2-1.6.5-2.2.9-.3.2-.5.3-.8.5-1.1.6-2.4 1.2-3.8 1.7-4.1 1.4-8.7 2.2-10.7 1.4 0 0 2.1-.8 4.1-1.5.2-.1.3-.1.5-.2h.1c1.1-.4 2.1-.8 2.4-.9.4-.2 3.3-.6 5.1-.9.4-.1.7-.1 1-.2.2-.1.5-.1.7-.2h.1c.2 0 .4-.1.5-.1h.1c.5-.2 1.1-.4 1.6-.6.9-.4 1.8-.8 2.6-1.2 1.1-.6 6.3-2.4 6.8-2.6.2-.1.8-.3 1.6-.5 1.8-.6 10.9-4.2 11.6-4.9l.3.6z"/>
  
            <path class="light-green" d="M606.8 69.6c-.5.2-1.1.4-1.6.6h-.1c-.2 0-.4.1-.5.1h-.1c-.3.1-.5.1-.7.2-.3.1-.6.1-1 .2-1.9.3-4.7.7-5.1.9-.4.2-1.3.5-2.4.9-.2-.1-.5-.1-.7-.2-.3-.1-.5-.2-.8-.2-.6-.2-1.1-.3-1.6-.4-.7-.2-1.4-.3-1.8-.4-.5-.1-.8-.2-.9-.2-.5-.2-3.6-.7-3.8-1 0 0 3.3.6 5.1.2.3-.1.6-.2.8-.3.6-.5 4.2-1.8 7.6-2.4 1-.2 1.9.2 2.7.6.7.4 1.2.7 1.6.9.1 0 .1 0 .2.1.3.1 1.3.2 2.3.2.1.2.5.2.8.2z"/>
  
          </g>
        </g>
  
        <g>
          <path class="extra-light-o" d="M616.7 56.5s-.1-6.2 3.1-7.2c.8-.3 1.1 1.9 1.1 2.2 0 .3-.3 2-2.3 3.5-1.6 1.1-1.9 1.5-1.9 1.5z"/>
  
          <path class="extra-light-o" d="M614.4 54.6s-3.1-2.4-1.8-3.9c.6-.7 2.8.5 3.2 1.1.6.9 1.4 3.3.8 4.6l-2.2-1.8z"/>
  
          <path class="extra-light-o" d="M616.5 56.8s-1.5-2.9-3.7-2.1c-2.2.9-2.4 2.4-2.4 2.4s3.5 2 6.1-.3z"/>
  
          <path class="extra-light-o" d="M616.3 56.7s-2.3 3.8.6 5.6c1 .6 1.9-3.9.4-5.5-.3-.3-1-.1-1-.1z"/>
  
          <path class="extra-light-o" d="M616.4 56.7l1.5-1s4.2-1 5.5 1.2c.6 1.1-3.2 1.4-4 1.2-.3-.1-2.1-1.5-3-1.4z"/>
  
          <path class="orange" d="M616 56.7s-1.3-.2-1.4-.1c-.1.1 1-.8 1.1-.8.1 0-.2-1.3-.3-1.4-.2-.1.9.9 1 1 .1.1 1-.7 1-.7l-.5 1.4.8.3-.9.3-.2.8-.6-.8z"/>
        </g>
      </g>
    </g>
    <g id="martini-bottom">
      <path class="dark_gray_page" d="M631.5 282.5s-28.6 127.1-31.2 132.4c-2.6 5.3 15.6 18 15.6 18l106.2.1s-10-43.1-10-49.1 2.7-79.3 10-83.3l-90.6-18.1z"/>
  
      <ellipse transform="rotate(-81.118 659.418 401.146)" class="light_orange" cx="659.4" cy="401.1" rx="2.7" ry="17.1"/>
  
      <path class="orange" d="M664.6 398.1c1 1.3 5.4 4.1 5 4.2-4.3 1-19-2.3-20.3-2.6.9.1 4.6-1.6 5.9-2.5 1.4-1 2.3-2 3.5-5.7 5.3-16.1 4.2-35.1 4.2-35.1l7.2 1.1s-7.3 17.2-7.1 34.1c0 3.8.6 5.1 1.6 6.5z"/>
  
      <path class="top-martini" d="M697.7 329.5l-6.5 6.6v-.1l-42.8-6.6-4.5-8.3z"/>
  
      <path class="st6" d="M677.2 339.4c-.2 0-.3-.1-.5-.2-.4-.3-.4-.9-.1-1.3l21.1-23.9c.3-.4.9-.4 1.3-.1.4.3.4.9.1 1.3L678 339.1c-.2.2-.5.3-.8.3z"/>
  
      <circle class="green" cx="691.7" cy="321.7" r="4.7"/><path class="st6" d="M691.2 336.1l-21.1 21.3-7.2-1.1-14.5-26.9 42.8 6.6z"/>
  
    </g>
  
    <g id="comp-little">
      <g class="fade5">
        <path class="medium_gray" d="M66.2 310.3l45.2-35.5s27.5 52.5 22 98l-52 32.6s6.6-58.1-15.2-95.1z"/>
  
        <path class="st6" d="M122.8 329.7c.2.5.1 1.1-.4 1.3l-28.2 13.7c-.4.2-1 0-1.3-.5l-10.2-21c-.2-.5-.1-1.1.4-1.3l28.2-13.7c.4-.2 1 0 1.3.5l10.2 21z"/>
  
        <path transform="rotate(-25.822 102.79 326.375)" class="light-blue" d="M88.1 315.8h29.4v21.3H88.1z"/>
  
        <path class="med-blue" d="M121.6 330.8l6.4-3.1.8 1.7s-.6 1.6-1.3 1.9L91 349c-.7.3-2.3-.2-2.3-.2l-.8-1.7 6.4-3.1 27.3-13.2z"/>
  
        <g>
  
          <path class="light-green" d="M98 324.6c0 .2.1.4.1.6l-2.4 1.2c0-.2 0-.5.1-.7l2.2-1.1zM100.7 323.3l.3.6-2.3 1.1c0-.2-.1-.4-.1-.6l2.1-1.1zM103.6 322.6l-2.1 1-.3-.6 1.9-.9.5.5zM106.8 321l-2.6 1.3c-.1-.2-.3-.3-.4-.5l2.4-1.2c.2.2.4.3.6.4z"/>
  
          <g>
  
            <path class="light-green" d="M101.2 331.5l-2.2 1.1c-.2-.1-.4-.3-.6-.4l2.3-1.1c.2 0 .4.2.5.4zM103.7 329.6l.3.6-2.2 1.1-.5-.5 2.4-1.2zM104.3 329.3l2.2-1.1c0 .2.1.4.1.6l-2 1-.3-.5zM109.6 326.7c0 .2 0 .5-.1.7l-2.4 1.2c0-.2-.1-.4-.1-.6l2.6-1.3z"/>
          </g>
  
          <g>
            <path class="light-green" d="M99.2 328.6l-2.6 1.3c0-.1-.1-.2-.1-.3 0-.1-.1-.2-.1-.3L99 328c0 .2.1.4.2.6zM102.2 326.5l.3.6-2.8 1.3-.3-.6 2.8-1.3zM105.6 325.5l-2.6 1.2-.3-.6 2.6-1.2.3.6zM108.9 323.6c0 .1.1.2.1.3l-2.8 1.4-.3-.6 2.8-1.4c.1.1.1.2.2.3z"/>
          </g>
  
          <g>
            <path class="green" d="M99.3 319.8c-3.7 1.8-5.3 6.3-3.5 10.1 1.8 3.7 6.3 5.3 10.1 3.5 3.7-1.8 5.3-6.3 3.5-10.1-1.8-3.7-6.3-5.3-10.1-3.5zm-.3 12.7c-.2-.1-.4-.3-.6-.4-.8-.6-1.4-1.3-1.9-2.2 0-.1-.1-.2-.1-.3 0-.1-.1-.2-.1-.3-.4-1-.6-2-.6-3 0-.2 0-.5.1-.7.2-1.6 1-3.1 2.3-4.2-.1.7-.2 1.8 0 3.2 0 .2.1.4.1.6 0 .1 0 .3.1.4.1.7.4 1.6.7 2.4l.3.6c.4.8.9 1.6 1.6 2.4.1.2.3.3.4.5.7.7 1.6 1.4 2.6 2-1.7.2-3.4-.1-4.9-1zm6.4.5c-.2.1-.4.2-.7.2-1.1-.6-2.1-1.2-2.9-2l-.5-.5c-.7-.7-1.2-1.5-1.6-2.4l-.3-.6c-.3-.7-.5-1.5-.7-2.3 0-.2-.1-.4-.1-.6 0-.2-.1-.4-.1-.6-.2-1.7.1-3 .1-3.4.2-.1.4-.2.6-.4l6.2 12.6zm.5-.3l-6-12.5c.1-.1.3-.1.4-.2.3.2 1.5.8 2.8 2 .2.1.3.3.5.5l.4.4c.5.6 1 1.3 1.4 2 .1.2.2.4.3.5.4.9.7 1.8.8 2.7 0 .2.1.4.1.6.1 1.1 0 2.3-.2 3.6-.2.2-.3.3-.5.4zm3.7-6c0 .2 0 .5-.1.7-.2 1.7-1.1 3.4-2.5 4.5.2-1.2.2-2.3.1-3.3 0-.2-.1-.4-.1-.6-.2-1-.5-1.9-.9-2.7l-.3-.6c-.4-.8-.9-1.5-1.4-2.1l-.3-.3c-.1-.2-.3-.3-.4-.5-1-1-1.9-1.6-2.6-2 1.7-.4 3.5-.1 5 .8.2.1.4.3.6.4.8.6 1.5 1.4 2 2.3 0 .1.1.2.1.3 0 .1.1.2.1.3.5.9.7 1.9.7 2.8z"/>
          </g>
        </g>
      </g>
    </g>
  
    <g id="hotel-bottom">
      <g class="fade6">
        <path class="medium_gray" d="M392.9 433l17.5-114.2 76.5 11.5-14.5 102.5z"/>
        
        <path transform="matrix(.06832 -.9977 .9977 .06832 13.513 791.913)" class="st6" d="M410.4 378.7h40.7v20h-40.7z"/>
  
        <path transform="matrix(.06832 -.9977 .9977 .06832 -2.429 804.88)" class="med-blue" d="M424.4 399.8H435v8h-10.6z"/>
  
        <path transform="matrix(.06832 -.9977 .9977 .06832 26.76 774.228)" class="light-blue" d="M425.4 370.3h5.1v5.1h-5.1z"/>
  
        <path transform="matrix(.06832 -.9977 .9977 .06832 33.525 782.544)" class="light-blue" d="M433.2 370.8h5.1v5.1h-5.1z"/>
  
        <path transform="matrix(.06832 -.9977 .9977 .06832 19.267 780.323)" class="light-blue" d="M424.9 377.3h5.1v5.1h-5.1z"/>
  
        <path transform="matrix(.06832 -.9977 .9977 .06832 26.031 788.639)" class="light-blue" d="M432.7 377.8h5.1v5.1h-5.1z"/>
  
        <path transform="matrix(.06832 -.9977 .9977 .06832 11.774 786.418)" class="light-blue" d="M424.4 384.4h5.1v5.1h-5.1z"/>
  
        <path transform="matrix(.06832 -.9977 .9977 .06832 18.538 794.734)" class="light-blue" d="M432.2 384.9h5.1v5.1h-5.1z"/>
  
        <path transform="matrix(.06832 -.9977 .9977 .06832 4.28 792.512)" class="light-blue" d="M423.9 391.4h5.1v5.1h-5.1z"/>
  
        <path transform="matrix(.06832 -.9977 .9977 .06832 11.045 800.828)" class="light-blue" d="M431.8 392h5.1v5.1h-5.1z"/>
  
        <path transform="matrix(.06832 -.9977 .9977 .06832 35.667 773.893)" class="med-blue" d="M431.6 356.1h1.1v23.4h-1.1z"/>
  
        <path transform="matrix(.06832 -.9977 .9977 .06832 37.004 772.806)" class="dark_navy" d="M431.5 353.3h1.6v26.5h-1.6z"/>
  
        <path transform="matrix(.06832 -.9977 .9977 .06832 23.817 820.515)" class="orange" d="M438.2 386.5h26v22h-26z"/>
  
        <path transform="matrix(.06832 -.9977 .9977 .06832 43.729 804.32)" class="light_orange" d="M446.7 370.1h11.6v17.2h-11.6z"/>
  
        <g>
          <path transform="matrix(.06832 -.9977 .9977 .06832 20.557 832.063)" class="light_orange" d="M450 402.2h11.6v5.6H450z"/>
  
          <path transform="matrix(.06832 -.9977 .9977 .06832 27.233 808.84)" class="extra-light-o" d="M443.9 387.1h5.6v5.6h-5.6z"/>
  
          <path transform="matrix(.06832 -.9977 .9977 .06832 18.806 815.696)" class="extra-light-o" d="M443.4 395h5.6v5.6h-5.6z"/>
  
          <path transform="matrix(.06832 -.9977 .9977 .06832 10.783 822.221)" class="extra-light-o" d="M442.8 402.6h5.6v5.6h-5.6z"/>
  
          <path transform="matrix(.06832 -.9977 .9977 .06832 35.971 819.525)" class="extra-light-o" d="M454 387.7h5.6v5.6H454z"/>
        </g>
        
        <path transform="matrix(.06832 -.9977 .9977 .06832 50.409 798.887)" class="orange" d="M452.4 362.8h1v19.3h-1z"/>
  
        <g>
          <path transform="matrix(.06832 -.9977 .9977 .06832 40.26 800.056)" class="extra2-light-o" d="M446.1 376.1h4.7v4.7h-4.7z"/>
  
          <path transform="matrix(.06832 -.9977 .9977 .06832 47.197 808.583)" class="extra2-light-o" d="M454.2 376.7h4.7v4.7h-4.7z"/>
        </g>
  
        <path class="green" d="M420.3 353.6l.8 2 2.2.5-1.7 1.5.3 2.2-2-1.2-2 .9.5-2.1-1.5-1.7 2.3-.2zM430 349.7l.9 2 2.2.5-1.7 1.5.2 2.2-1.9-1.2-2.1.9.5-2.2-1.5-1.6 2.3-.2zM441.5 349.3l.8 2.1 2.2.4-1.7 1.5.2 2.2-1.9-1.1-2 .9.5-2.2-1.5-1.7 2.2-.2zM451.9 351.2l.9 2 2.2.5-1.7 1.5.2 2.2-1.9-1.2-2.1.9.6-2.2-1.5-1.6 2.2-.2zM461.7 356.6l.8 2.1 2.2.5-1.7 1.4.3 2.3-2-1.2-2 .9.5-2.2-1.5-1.7 2.3-.2z"/>
      </g>
    </g>
  
    <g id="chemistry-little">
  
      <g class="fade5">
  
        <path class="dark_gray_page" d="M413 148.6l58 13.5 14-74.5-58-14.5s-15.3 67.8-14 75.5z"/>
  
        <path class="orange" d="M470.5 111.3c-.1.5-.6.8-1.1.8l-8.2-1.6c-.5-.1-.8-.6-.7-1.1v-.2c.1-.5.6-.8 1.1-.7l8.2 1.6c.5.1.8.6.7 1.1v.1z"/>
  
        <g id="XMLID_42_">
          <path class="st6" d="M468 121.1c-1.1 5.8-2.4 12.9-3.1 16.3-.4 2.1-2.3 3.6-4.9 3.1l-.4-.1c-2.6-.5-3.8-2.6-3.4-4.7.6-3.2 1.9-9.9 2.9-15.5 0 0 2.8-1.7 4.9-.5 2.6 1.4 4 1.4 4 1.4z"/>
  
          <path class="light_orange" d="M469.7 112.1s-.8 4-1.7 9c0 0-1.3 0-3.8-1.4-2.1-1.2-4.9.5-4.9.5 1-5.3 1.8-9.7 1.8-9.7l8.6 1.6z"/>
  
        </g>
  
        <g>
          <path class="orange" d="M456.6 98.8c-.1.5-.6.8-1.1.7l-11.9-2.3c-.5-.1-.8-.5-.8-1v-.1c.1-.5.6-.8 1.1-.7l11.9 2.3c.5.1.8.5.8 1v.1z"/>
  
          <g id="XMLID_41_">
            <path class="med-blue" d="M459.5 137.1s.7 3.3-2.5 2.7l-29.5-5.6c-3.2-.6-1.4-3.4-1.4-3.4l10.5-13.4s4.6-1.5 8.4.2c5.3 2.4 8.3-1.3 8.4-1.4l6.1 20.9z"/>
  
            <path class="light_orange" d="M453.3 116.2c0 .1-3.1 3.8-8.4 1.4-3.8-1.7-8.4-.2-8.4-.2l4.8-6.2 2.6-13.9 11.1 2.1-2.6 13.9.9 2.9z"/>
  
          </g>
  
          <ellipse transform="rotate(-79.281 435.947 129.034)" class="light-bubbles" cx="436" cy="129" rx="3.4" ry="3.4"/>
  
          <ellipse transform="rotate(-79.281 441.673 124.368)" class="light-bubbles" cx="441.7" cy="124.4" rx="2" ry="2"/>
  
          <ellipse transform="rotate(-79.281 442.416 129.804)" class="light-bubbles" cx="442.4" cy="129.8" rx="1.4" ry="1.4"/>
  
        </g>
      </g>
    </g>
  
    <g id="hotel-tiny">
      <g class="fade6">
        <path class="white_page" d="M34.5 230.8l6.7 55s55.3-26.5 56.8-37l-4.5-53s-29.5 26.5-59 35z"/>
  
        <path transform="rotate(-20.024 61.852 239.887)" class="dark_navy" d="M49.4 233.7h25V246h-25z"/>
  
        <path transform="rotate(-20.024 53.176 243.048)" class="med-blue" d="M49.9 240.6h6.5v4.9h-6.5z"/>
  
        <path transform="rotate(-20.024 70.078 234.325)" class="light-blue" d="M68.5 232.8h3.1v3.1h-3.1z"/>
  
        <path transform="rotate(-20.024 71.727 238.85)" class="light-blue" d="M70.2 237.3h3.1v3.1h-3.1z"/>
  
        <path transform="rotate(-20.024 66 235.811)" class="light-blue" d="M64.4 234.2h3.1v3.1h-3.1z"/>
  
        <path transform="rotate(-20.024 67.65 240.337)" class="light-blue" d="M66.1 238.8h3.1v3.1h-3.1z"/>
  
        <path transform="rotate(-20.024 61.922 237.297)" class="light-blue" d="M60.4 235.7h3.1v3.1h-3.1z"/>
  
        <path transform="rotate(-20.024 63.571 241.823)" class="light-blue" d="M62 240.3h3.1v3.1H62z"/>
  
        <path transform="rotate(-20.024 57.844 238.784)" class="light-blue" d="M56.3 237.2h3.1v3.1h-3.1z"/>
  
        <path transform="rotate(-20.024 59.493 243.31)" class="light-blue" d="M57.9 241.7H61v3.1h-3.1z"/>
  
        <path transform="rotate(-20.024 73.909 235.492)" class="med-blue" d="M73.6 228.3h.7v14.4h-.7z"/>
  
        <path transform="rotate(-20.024 74.636 235.227)" class="dark_navy" d="M74.1 227.1h1v16.3h-1z"/>
  
        <path transform="rotate(-20.024 62.022 253.547)" class="orange" d="M54 246.8h15.9v13.5H54z"/>
  
        <path transform="rotate(-20.024 72.858 249.598)" class="light_orange" d="M69.3 244.3h7.1v10.5h-7.1z"/>
  
        <g>
          <path transform="rotate(-20.024 58.94 257.971)" class="light_orange" d="M55.4 256.2h7.1v3.4h-7.1z"/>
  
          <path transform="rotate(-20.024 65.19 249.092)" class="extra-light-o" d="M63.5 247.4h3.4v3.4h-3.4z"/>
  
          <path transform="rotate(-20.024 60.603 250.763)" class="extra-light-o" d="M58.9 249h3.4v3.4h-3.4z"/>
  
          <path transform="rotate(-20.024 56.236 252.355)" class="extra-light-o" d="M54.5 250.6h3.4v3.4h-3.4z"/>
  
          <path transform="rotate(-20.024 67.329 254.914)" class="extra-light-o" d="M65.6 253.2H69v3.4h-3.4z"/>
        </g>
  
        <path transform="rotate(-20.024 76.493 248.273)" class="orange" d="M76.2 242.3h.6v11.8h-.6z"/>
  
        <g>
          <path transform="rotate(-20.024 72.013 247.277)" class="extra2-light-o" d="M70.6 245.8h2.9v2.9h-2.9z"/>
  
          <path transform="rotate(-20.024 73.704 251.918)" class="extra2-light-o" d="M72.3 250.5h2.9v2.9h-2.9z"/>
  
        </g>
  
        <path class="green" d="M79 225.3l-1 1 .3 1.3-1.3-.6-1.2.7.2-1.4-1-.9 1.4-.3.5-1.2.7 1.2zM83.6 229.7l-1 1 .3 1.4-1.2-.6-1.2.7.1-1.4-1-.9 1.4-.3.5-1.2.7 1.2zM86.6 236.1l-.9 1 .2 1.3-1.2-.6-1.2.7.2-1.4-1-.9 1.3-.2.6-1.3.7 1.2zM88.2 242.4l-1 1 .3 1.3-1.2-.6-1.2.7.2-1.3-1.1-1 1.4-.2.6-1.3.6 1.2zM87.6 249.2l-1 1 .3 1.4-1.3-.6-1.2.7.2-1.4-1-.9 1.4-.3.5-1.2.7 1.2z"/>
      </g>
    </g>
  
    <g id="chemistry-top-right">
      <g class="fade6">
        <path class="white_page" d="M682.4 75.5l58 13.5 14-74.5-58-14.5s-15.3 67.7-14 75.5z"/>
  
        <path class="orange" d="M739.3 37.8c-.1.5-.6.8-1.1.8L730 37c-.5-.1-.8-.6-.7-1.1v-.2c.1-.5.6-.8 1.1-.7l8.2 1.6c.5.1.8.6.7 1.1v.1z"/>
  
        <g id="XMLID_40_">
          <path class="st6" d="M736.8 47.6c-1.1 5.8-2.4 12.9-3.1 16.3-.4 2.1-2.3 3.6-4.9 3.1l-.4-.1c-2.6-.5-3.8-2.6-3.4-4.7.6-3.2 1.9-9.9 2.9-15.5 0 0 2.8-1.7 4.9-.5 2.7 1.4 4 1.4 4 1.4z"/>
  
          <path class="light_orange" d="M738.5 38.6s-.8 4-1.7 9c0 0-1.3 0-3.8-1.4-2.1-1.2-4.9.5-4.9.5 1-5.3 1.8-9.7 1.8-9.7l8.6 1.6z"/>
        </g>
  
        <g>
          <path class="orange" d="M725.4 25.3c-.1.5-.6.8-1.1.7l-11.9-2.3c-.5-.1-.8-.5-.8-1v-.1c.1-.5.6-.8 1.1-.7l11.9 2.3c.5.1.8.5.8 1v.1z"/>
  
          <g id="XMLID_39_">
            <path class="med-blue" d="M728.3 63.6s.7 3.3-2.5 2.7l-29.5-5.6c-3.2-.6-1.4-3.4-1.4-3.4l10.5-13.4s4.6-1.5 8.4.2c5.3 2.4 8.3-1.3 8.4-1.4l6.1 20.9z"/>
  
            <path class="light-orange" d="M722.1 42.7c0 .1-3.1 3.8-8.4 1.4-3.8-1.7-8.4-.2-8.4-.2l4.8-6.2 2.6-13.9 11.1 2.1-2.6 13.9.9 2.9z"/>
          </g>
  
          <ellipse transform="rotate(-79.281 704.754 55.572)" class="light-bubbles" cx="704.8" cy="55.6" rx="3.4" ry="3.4"/>
  
          <ellipse transform="rotate(-79.281 710.48 50.906)" class="light-bubbles" cx="710.5" cy="50.9" rx="2" ry="2"/>
  
          <ellipse transform="rotate(-79.281 711.223 56.342)" class="light-bubbles" cx="711.3" cy="56.3" rx="1.4" ry="1.4"/>
        </g>
      </g>
    </g>
  </svg>

    <div class="text">
        <div class="text-inner">
            <h1> Upss!! </h1>
            <h2> Parece que la página que estás buscando no existe. </h2>
            <a [routerLink]="['/inicio']">
                <div id="home" class="button">
                    <h3>Inicio</h3>
                </div>
            </a>
        </div>

    </div>

</div>