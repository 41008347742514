<section class="intro-hero" id="encabezado">

    <div class="container">
        <div class="row">

            <div class="col-sm-12 text-center">
                <!-- <img class="intro-hero-img" src="assets/img/hero-round.png" alt=""> -->
                <h1 class="hero-title"><strong>{{nombre}}</strong></h1>
                <p class="hero-tagline">
                    Me encanta
                    <span class="typelist-skill" data-typist="el desarrollo de software, emprender, innovar, leer, aprender">la tecnologia</span>
                </p>
            </div>

            <div class="col-sm-6">
                <ul class="list-inline social social-intro">
                    <li><a href="https://www.facebook.com/breinergonza" target="_blank"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="https://twitter.com/breinergonza" target="_blank"><i class="fa fa-twitter"></i></a></li>
                    <li><a href="https://plus.google.com/u/0/+breinergonzalez" target="_blank"><i class="fa fa-google-plus"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/breitner-enrique-gonzález-angarita-847a2840" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                    <li><a href="https://github.com/breinergonza" target="_blank"><i class="fa fa-github"></i></a></li>
                </ul>
            </div>

            <div class="col-sm-6">
                <a href="https://vimeo.com/264838990" class="btn-play-video">
                    <svg viewBox="0 0 294.843 294.843" style="enable-background:new 0 0 294.843 294.843;" xml:space="preserve">
            <path d="M278.527,79.946c-10.324-20.023-25.38-37.704-43.538-51.132c-2.665-1.97-6.421-1.407-8.392,1.257s-1.407,6.421,1.257,8.392            c16.687,12.34,30.521,28.586,40.008,46.983c9.94,19.277,14.98,40.128,14.98,61.976c0,74.671-60.75,135.421-135.421,135.421
            S12,222.093,12,147.421S72.75,12,147.421,12c3.313,0,6-2.687,6-6s-2.687-6-6-6C66.133,0,0,66.133,0,147.421
            s66.133,147.421,147.421,147.421s147.421-66.133,147.421-147.421C294.842,123.977,289.201,100.645,278.527,79.946z"/>
            <path d="M109.699,78.969c-1.876,1.067-3.035,3.059-3.035,5.216v131.674c0,3.314,2.687,6,6,6s6-2.686,6-6V94.74l88.833,52.883
            l-65.324,42.087c-2.785,1.795-3.589,5.508-1.794,8.293c1.796,2.786,5.508,3.59,8.294,1.794l73.465-47.333
            c1.746-1.125,2.786-3.073,2.749-5.15c-0.037-2.077-1.145-3.987-2.93-5.05L115.733,79.029
            C113.877,77.926,111.575,77.902,109.699,78.969z"/>
          </svg>
                    <span>Feliz <br> Lunes</span>
                </a>
            </div>

        </div>
    </div>
</section>