<main class="content-wrap">

    <section class="about-me" id="about">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5 wow zoomIn">
                    <h2 class="section-title">
                        Sobre mí
                    </h2>
                    <span class="background-text">sobre mí</span>
                </div>
            </div>

            <div class="row">

                <div class="col-lg-4 text-center wow fadeInLeft">
                    <img src="assets/img/about-large.jpg" alt="">
                </div>

                <div class="col-lg-8 about-me-text pl-lg-5">
                    <p class="lead my-4">
                        Hola! Soy <strong>Breitner González</strong>, y me apasiona todo aquello que tenga que ver con tecnología, me puedes encontrar en
                        <a href="https://www.facebook.com/breinergonza" class="facebook-link" target="_blank">Facebook</a> ,
                        <a href="https://twitter.com/breinergonza" class="twitter-link" target="_blank">Twitter</a> y
                        <a href="https://www.linkedin.com/in/breitner-enrique-gonzález-angarita-847a2840" class="linkedin-link" target="_blank">LinkedIn</a>
                    </p>
                    <p class="mb-5">
                        Soy un Ingeniero de Sistemas con experiencia en Desarrollo de Software en frameworks como ASP.Net MVC, ASP Web Forms, Angular, Android Native, Node, Ionic, Cordova, PhoneGap, Xamarin en lenguajes como C#, VB, TypeScript y Java
                    </p>
                    <h4>Información Personal</h4>
                    <ul class="personal-info list-inline clearfix mb-5">
                        <li><strong>Nombre :</strong>Breitner E. González Angarita</li>
                        <li><strong>Ciudad :</strong> Bogotá</li>
                        <li><strong>Celular :</strong> +57 311 352 05 74</li>
                        <li><strong>Email :</strong> <a href="mailto:breinergonza@hotmail.com">breinergonza@hotmail.com</a></li>
                        <li><strong>Job :</strong> DevOps Team Leader</li>
                        <li><strong>Skype :</strong> breinergonza</li>
                    </ul>
                    <a href="assets/files/BreitnerEnriqueGonzalezAngarita-CV.pdf" target="_blank" class="btn btn-theme btn-active mr-md-3 wow slideInUp">Descarga CV</a>
                    <a [routerLink]="['/contacto']" class="btn btn-theme wow slideInUp">Contactame</a>
                </div>
            </div>

        </div>
    </section>





    <section class="my-interest bg-gray">

        <div class="container">

            <div class="row">
                <div class="col-sm-12 text-center mb-5 wow zoomIn">
                    <h2 class="section-title">
                        Mis Intereses
                    </h2>
                    <span class="background-text">Mis Intereses</span>
                </div>
            </div>

            <div class="row">

                <div class="col-lg-2 col-md-4 wow slideInUp" data-wow-duration="1s" data-wow-delay=".1s">
                    <div class="interest-block">
                        <span class="lnr lnr-bicycle"></span>
                        <p> Montar Bicicleta </p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4 wow slideInUp" data-wow-duration="1s" data-wow-delay=".2s">
                    <div class="interest-block">
                        <span class="lnr lnr-camera-video"></span>
                        <p> Ver Peliculas </p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4 wow slideInUp" data-wow-duration="1s" data-wow-delay=".3s">
                    <div class="interest-block">
                        <span class="lnr lnr-book"></span>
                        <p> Leer </p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4 wow slideInUp" data-wow-duration="1s" data-wow-delay=".4s">
                    <div class="interest-block">
                        <span class="lnr lnr-dinner"></span>
                        <p> Cocinar </p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4 wow slideInUp" data-wow-duration="1s" data-wow-delay=".5s">
                    <div class="interest-block">
                        <span class="lnr lnr-bus"></span>
                        <p> Viajar </p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4 wow slideInUp" data-wow-duration="1s" data-wow-delay=".6s">
                    <div class="interest-block">
                        <span class="lnr lnr-laptop-phone"></span>
                        <p> Desarrollar </p>
                    </div>
                </div>

            </div>

        </div>
    </section>



    <!-- Mis premios???
      
  <section class="awards">

    <div class="container">

      <div class="row">
       <div class="col-sm-12 text-center mb-5 wow zoomIn">
         <h2 class="section-title">
           My Awards
         </h2>
         <span class="background-text">Awards</span>
       </div>
      </div>

      <div class="row">

        <div class="awards-slides owl-carousel">

          <div class="awards-item">
            <div class="awards-logo">
              <img src="assets/img/awards/1.jpg" alt="">
            </div>
            <h4 class="block-title my-4">Excellence Award 2012</h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend the option congue nihil imper per tem por legere me doming vulputate velit esse molestie.
            </p>
          </div>

          <div class="awards-item">
            <div class="awards-logo">
              <img src="assets/img/awards/2.jpg" alt="">
            </div>
            <h4 class="block-title my-4">Excellence Award 2012</h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend the option congue nihil imper per tem por legere me doming vulputate velit esse molestie.
            </p>
          </div>

          <div class="awards-item">
            <div class="awards-logo">
              <img src="assets/img/awards/3.jpg" alt="">
            </div>
            <h4 class="block-title my-4">Excellence Award 2012</h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend the option congue nihil imper per tem por legere me doming vulputate velit esse molestie.
            </p>
          </div>

          <div class="awards-item">
            <div class="awards-logo">
              <img src="assets/img/awards/1.jpg" alt="">
            </div>
            <h4 class="block-title my-4">Excellence Award 2012</h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend the option congue nihil imper per tem por legere me doming vulputate velit esse molestie.
            </p>
          </div>

          <div class="awards-item">
            <div class="awards-logo">
              <img src="assets/img/awards/2.jpg" alt="">
            </div>
            <h4 class="block-title my-4">Excellence Award 2012</h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend the option congue nihil imper per tem por legere me doming vulputate velit esse molestie.
            </p>
          </div>

          <div class="awards-item">
            <div class="awards-logo">
              <img src="assets/img/awards/3.jpg" alt="">
            </div>
            <h4 class="block-title my-4">Excellence Award 2012</h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend the option congue nihil imper per tem por legere me doming vulputate velit esse molestie.
            </p>
          </div>

          <div class="awards-item">
            <div class="awards-logo">
              <img src="assets/img/awards/3.jpg" alt="">
            </div>
            <h4 class="block-title my-4">Excellence Award 2012</h4>
            <p>
              Nam liber tempor cum soluta nobis eleifend the option congue nihil imper per tem por legere me doming vulputate velit esse molestie.
            </p>
          </div>

        </div>
      </div>

    </div>
  </section>  -->

</main>