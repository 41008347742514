<nav id="nav">
    <div class="sm-nav-toggler">
        <span class="lnr lnr-menu"></span>
        <span class="lnr lnr-cross"></span>
    </div>
    <ul class="main-menu">
        <li>
            <a [routerLink]="['/inicio']">
                <span class="lnr lnr-home"></span>
                <span class="nav-text">Inicio</span>
            </a>
        </li>
        <li>
            <a class="scrolling" routerLink="/inicio" [queryParams]="{debug: true}" fragment="about">
                <span class="lnr lnr-user"></span>
                <span class="nav-text">Info</span>
            </a>
        </li>
        <li>
            <a [routerLink]="['/curriculum']">
                <span class="lnr lnr-license"></span>
                <span class="nav-text">Currículum</span>
            </a>
        </li>
        <li>
            <a [routerLink]="['/servicios']">
                <span class="lnr lnr-cog"></span>
                <span class="nav-text">Servicios</span>
            </a>
        </li>
        <!-- <li>
            <a [routerLink]="['/portafolio']" target="_blank">
                <span class="lnr lnr-briefcase"></span>
                <span class="nav-text">Portafolio</span>
            </a>
        </li>
        <li>
            <a [routerLink]="['/blog']">
                <span class="lnr lnr-leaf"></span>
                <span class="nav-text">Blog</span>
            </a>
        </li> -->
        <li>
            <a [routerLink]="['/contacto']">
                <span class="lnr lnr-phone-handset"></span>
                <span class="nav-text">Contactame</span>
            </a>
        </li>
    </ul>
    <div class="menu-bg"></div>
</nav>